import {
    capitalize,
    getDateObject,
    getDateObjectWithResettedTime,
    getDateTimeString,
    getValuesOfNumericEnum,
} from '@advanza/func'
import { Col, Row } from '@advanza/grid'
import { SelectSimple } from '@advanza/input'
import Modal, { useScrollModal } from '@advanza/modal'
import { Button, Divider, Icon, PreIcon } from '@advanza/ui'
import DateInput from 'components/pauses/DateInput'
import { DaySchemeSet, PauseErrorSet, PauseTypeSet } from 'components/pauses/pauseEnums'
import AnimatedPages from 'components/ui/AnimatedPages'
import SafeButton from 'components/ui/SafeButton'
import { addDays, addMonths, differenceInCalendarDays, format, getDate } from 'date-fns'
import { DAY_SCHEMES, countDays, useProvidersDate } from 'hooks/providersDatesHooks'
import { Provider } from 'interfaces/Providers'
import { useState } from 'react'
import style from './availabilityCalendarDateEdit.module.scss'

interface Props {
    addMode?: boolean
    provider: Provider
    providerDateId?: number | string
    onSucces?: () => void
    store?: string
}

const AvailabilityCalendarDateEdit = ({
    addMode = false,
    provider,
    providerDateId,
    onSucces,
    store,
}: Props) => {
    const { openModal, closeModal, renderModal } = useScrollModal()
    const {
        providersDate,
        onChangeEntity,
        onSave,
        isSaving,
        errors,
        setErrors,
        onCancel,
        onDelete,
    } = useProvidersDate(providerDateId, onSucces, store)

    const onOpen = () => {
        if (addMode) {
            onChangeEntity(
                {
                    service_provider_id: provider.service_provider_id,
                    pause_type: PauseTypeSet.WORK,
                    day_scheme: DaySchemeSet.NO_REPEAT,
                    date_from: '',
                    date_to: '',
                },
                true
            )
        }
        openModal()
    }

    const isRepeated = providersDate?.day_scheme > 0

    const [editDayScheme, setEditDayScheme] = useState(
        providersDate.day_scheme !== DaySchemeSet.NO_REPEAT
    )
    const [showError, setShowError] = useState<PauseErrorSet>(PauseErrorSet.NONE)
    const positionMap: { [key: number]: string } = {
        1: 'first',
        2: 'second',
        3: 'third',
        4: 'fourth',
        5: 'fifth',
    }
    const describeRepeatedDay = (dayScheme: DaySchemeSet, dateFrom: string) => {
        const date = getDateObjectWithResettedTime(dateFrom)
        if (dayScheme === DaySchemeSet.NO_REPEAT) {
            return 'Not repeated'
        } else if (dayScheme === DaySchemeSet.WEEKLY) {
            return `Weekly on ${getDateTimeString(date, 'EEEE')}`
        } else if (dayScheme === DaySchemeSet.BIWEEKLY) {
            return `Biweekly on ${getDateTimeString(date, 'EEEE')}`
        } else if (dayScheme === DaySchemeSet.MONTHLY) {
            return `Every ${
                positionMap[Math.floor((getDate(date) - 1) / 7) + 1]
            } ${getDateTimeString(date, 'EEEE')} of the month`
        } else if (dayScheme == DaySchemeSet.YEARLY) {
            return `Yearly on ${getDateTimeString(date, 'd MMMM')}`
        }
    }
    const getDbDateString = (dateTime: string | Date) => {
        const dateObject = getDateObject(dateTime)
        return format(dateObject, 'yyyy-MM-dd')
    }
    const formatToDateObjectOrUndefined = (date: string | undefined) =>
        !date ? undefined : new Date(date)
    const repeatingSchemeIsAllowed =
        !providersDate.date_to || providersDate.date_to === providersDate.date_from
    const onChangeDate = (date: Date, key: 'date_from' | 'date_to') => {
        // if it's just the first date that is set, update it and return
        if (
            (key === 'date_from' && !providersDate.date_to) ||
            (key === 'date_to' && !providersDate.date_from)
        ) {
            onChangeEntity({
                [key]: getDbDateString(date),
            })
            return
        }

        const dateFrom = key === 'date_from' ? date : new Date(providersDate.date_from)
        const dateTo = key === 'date_to' ? date : new Date(providersDate.date_to)
        const invalidDateRange = differenceInCalendarDays(dateTo, dateFrom) < 0
        const maxEndDateTo = provider?.isSignedOrFreeTrial
            ? addDays(dateFrom, 59)
            : addMonths(dateFrom, 6)
        const rangeLongerThanLimit = dateTo > maxEndDateTo

        if (rangeLongerThanLimit) {
            // correct date_to to maxEndDateTo
            onChangeEntity({
                date_from: getDbDateString(dateFrom),
                date_to: getDbDateString(maxEndDateTo),
            })
        } else {
            onChangeEntity({
                [key]: getDbDateString(date),
            })
        }

        setShowError(
            invalidDateRange
                ? PauseErrorSet.INVALID_DATE_RANGE
                : rangeLongerThanLimit
                ? PauseErrorSet.RANGE_LONGER_THAN_MAX_PERIOD
                : PauseErrorSet.NONE
        )
    }

    const editModal = renderModal({
        heading: (
            <Row middle="xs">
                <Col x>
                    {addMode ? 'Add' : 'Edit'} blocked period{' '}
                    {isRepeated && (
                        <>
                            (<Icon name="repeat" fontSize={16} red={providersDate.pause_deleted} />{' '}
                            {/* @ts-ignore*/}
                            <small>{DAY_SCHEMES[providersDate.day_scheme]}</small>)
                        </>
                    )}
                    {providersDate.date_to && !isRepeated && (
                        <>
                            (
                            {countDays(
                                providersDate.date_from
                                    ? new Date(providersDate.date_from)
                                    : new Date(),
                                new Date(providersDate.date_to)
                            )}{' '}
                            days)
                        </>
                    )}{' '}
                </Col>
                {!addMode && (
                    <Col x>
                        <Button name="bordered">
                            <SafeButton
                                action={() => onDelete().then(() => closeModal())}
                                cancelText={false}
                                // @ts-ignore
                                confirmMessage="Confirm delete?"
                                buttonText={
                                    <>
                                        <Icon name="delete" /> Delete blocked period
                                    </>
                                }
                            />
                        </Button>
                    </Col>
                )}
            </Row>
        ),
        headingBgColor: 'transparent',
        body: (
            <>
                <div className={style.dialogEditDay}>
                    <Divider ml />
                    <div className="poppins">Not available</div>
                    <Divider sm />
                    <div className={style.dateInputWrapper}>
                        <DateInput
                            className={style.dateInput}
                            modalTitle={
                                providersDate.day_scheme !== DaySchemeSet.NO_REPEAT
                                    ? 'Select the date'
                                    : 'Select the start date'
                            }
                            date={formatToDateObjectOrUndefined(providersDate?.date_from)}
                            setDate={(date) => {
                                onChangeDate(date, 'date_from')
                            }}
                            defaultMonth={formatToDateObjectOrUndefined(providersDate?.date_from)}
                            icon={
                                providersDate?.day_scheme !== DaySchemeSet.NO_REPEAT
                                    ? 'event_repeat'
                                    : 'date_range'
                            }
                        />
                        {providersDate?.day_scheme === DaySchemeSet.NO_REPEAT && (
                            <>
                                to{' '}
                                <DateInput
                                    className={style.dateInput}
                                    modalTitle="Select the end date"
                                    placeholder="Same day"
                                    date={formatToDateObjectOrUndefined(providersDate?.date_to)}
                                    setDate={(date) => {
                                        onChangeDate(date, 'date_to')
                                    }}
                                    defaultMonth={formatToDateObjectOrUndefined(
                                        providersDate?.date_to || providersDate?.date_from
                                    )}
                                    onDeselect={() =>
                                        onChangeEntity({
                                            date_to: '',
                                        })
                                    }
                                    error={
                                        showError === PauseErrorSet.RANGE_LONGER_THAN_MAX_PERIOD
                                            ? 'Adjusted'
                                            : ''
                                    }
                                />
                            </>
                        )}
                    </div>
                    {showError !== PauseErrorSet.NONE && (
                        <div className={style.errorMessage}>
                            <Divider s />
                            <PreIcon icon="error" red>
                                {showError === PauseErrorSet.RANGE_LONGER_THAN_MAX_PERIOD
                                    ? `You can block a maximum of ${
                                          provider?.isSignedOrFreeTrial ? '60 days' : '6 months'
                                      }.`
                                    : showError === PauseErrorSet.INVALID_DATE_RANGE
                                    ? 'You must select an end date on or after the start date.'
                                    : null}
                            </PreIcon>
                        </div>
                    )}
                    {repeatingSchemeIsAllowed && (
                        <>
                            <Divider sm />
                            <AnimatedPages page={editDayScheme ? 1 : 2}>
                                <div>
                                    {/* @ts-ignore */}
                                    <SelectSimple
                                        value={`${providersDate.day_scheme}`}
                                        options={getValuesOfNumericEnum(DaySchemeSet).map(
                                            (label, i) => ({
                                                value: i,
                                                name: capitalize(
                                                    describeRepeatedDay(
                                                        label,
                                                        providersDate.date_from
                                                    )
                                                ),
                                            })
                                        )}
                                        onChange={(e: any) => {
                                            onChangeEntity({
                                                day_scheme: parseInt(e.target.value, 10),
                                            })
                                        }}
                                    />
                                </div>
                                <div className={style.repeatButtonWrapper}>
                                    <Button name="text" onClick={() => setEditDayScheme(true)}>
                                        <PreIcon icon="restart_alt">Repeat</PreIcon>
                                    </Button>
                                </div>
                            </AnimatedPages>
                        </>
                    )}
                    <Divider ml />
                </div>
            </>
        ),
        buttons: [
            {
                text: 'Cancel',
                name: 'link',
                func: onCancel,
                disabled: isSaving,
            },
            {
                text: isSaving ? 'Saving...' : 'Save availability',
                func: onSave,
                disabled: isSaving || !providersDate._isTouched || !providersDate.date_from,
            },
        ],
        closeButton: {
            func: onCancel,
        },
        maxWidth: '420px',
    })

    return (
        <>
            <Button name="text" onClick={onOpen}>
                {addMode ? 'Add blocked period' : <Icon name="edit" />}
            </Button>
            {editModal}
            {/* @ts-ignore */}
            <Modal open={errors} close={() => setErrors(null)}>
                <h3>Error:</h3>
                <Divider />
                {JSON.stringify(errors)}
            </Modal>
        </>
    )
}

export default AvailabilityCalendarDateEdit
