import { getDateFnsLocale, getDateTimeString } from '@advanza/func'
import { InputField } from '@advanza/input'
import rdpStyle from '@advanza/input/reactDayPickerStyle.module.css'
import { ScrollModal } from '@advanza/modal'
import { Button, Divider, Icon } from '@advanza/ui'
import { subDays } from 'date-fns'
import { useState } from 'react'
import { DayPicker } from 'react-day-picker'

interface Props {
    className?: string
    icon?: string
    placeholder?: string | null
    modalTitle?: string
    date?: Date
    setDate: (date: Date) => void
    defaultMonth?: Date
    error?: string | boolean | null
    onDeselect?: () => void
}

const DateInput = ({
    className,
    icon = 'date_range',
    placeholder,
    modalTitle,
    date,
    setDate,
    defaultMonth,
    error,
    onDeselect,
}: Props) => {
    const [showDayPicker, setShowDayPicker] = useState(false)

    const onSelectDate = (date: Date) => {
        setShowDayPicker(false)
        setDate(date)
    }

    return (
        <>
            {/*@ts-ignore*/}
            <InputField
                after={<Icon name={icon} primColor />}
                hideAfterIconStyle
                classNames={[className]}
                inputPlaceholder={placeholder}
                value={date ? getDateTimeString(date, 'dd-MM-yyyy') : ''}
                onFocus={() => setShowDayPicker(true)}
                readOnly={true}
                error={error}
            />
            <ScrollModal
                heading={modalTitle}
                headingBgColor="transparent"
                body={
                    <>
                        <Divider ml />
                        <div
                            style={{
                                backgroundColor: '#fff',
                                border: '1px solid #dbdce0',
                                borderRadius: '5px',
                                padding: '16px',
                            }}>
                            <DayPicker
                                locale={getDateFnsLocale()}
                                disabled={(date) => date <= subDays(new Date(), 1)}
                                fromMonth={new Date()}
                                defaultMonth={defaultMonth}
                                selected={date}
                                onDayClick={onSelectDate}
                                classNames={rdpStyle}
                                footer={
                                    onDeselect ? (
                                        <>
                                            <Divider s />
                                            <Button
                                                name="text2"
                                                onClick={() => {
                                                    onDeselect()
                                                    setShowDayPicker(false)
                                                }}>
                                                Reset selected day
                                            </Button>
                                        </>
                                    ) : undefined
                                }
                            />
                        </div>
                        <Divider ml />
                    </>
                }
                isOpen={showDayPicker}
                onCloseModal={() => setShowDayPicker(false)}
                closeOnOutsideClick
                maxWidth={400}
            />
        </>
    )
}

export default DateInput
