import { getRangeFromObject } from 'components/misc/misc'
import MunicipalitiesMap from 'components/serviceInfo/MunicipalityMap'
import React from 'react'
import { useSelector } from 'react-redux'
import ServiceStatsContainer from '../../containers/ServiceStatsContainer'

const ServiceCompetitionGraph = ({ getStatsAndOptions, onMapReady }) => {
    const {
        stats: { pros, nrPros, prosData: municipalityCounts },
    } = getStatsAndOptions('competitionStats')
    const country = useSelector((state) => state.countries.countries[0])
    const { range, min } = getRangeFromObject(municipalityCounts)

    function getHoverContent({ id, municipality }) {
        if (!municipalityCounts[id]) {
            return '0'
        }

        return `<div>${municipality.name}</div>
        <b>${municipalityCounts[id]}</b> (${((municipalityCounts[id] / nrPros) * 100).toFixed(2)}%)
            `
    }

    function getHeatColor({ id }) {
        if (!municipalityCounts[id]) {
            return {
                fillColor: 'rgba(0,0,0,0)',
            }
        }

        const amount = municipalityCounts[id]
        const percentage = ((amount / (range + min)) * 100).toFixed(2)
        const fillColor = `hsl(${100 - percentage},89%,46%)`
        return { fillColor }
    }

    return (
        <div>
            <MunicipalitiesMap
                countryCode={country}
                useHeatMap
                height={country === 'NL' ? 600 : 375}
                width={country === 'NL' ? 500 : 600}
                key="coverage_pros"
                id="competition"
                getHoverContent={getHoverContent}
                getHeatColorVars={getHeatColor}
                markersData={pros}
                onMapReady={onMapReady}
                refreshTrigger={municipalityCounts}
            />
        </div>
    )
}

export default ServiceStatsContainer(ServiceCompetitionGraph)
