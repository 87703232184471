import { Section } from '@advanza/advanza_generic'
import { call } from '@advanza/api'
import {
    PROFILE_STATUS_CHURNED,
    PROFILE_STATUS_DISABLED,
    PROFILE_STATUS_DISABLED_BAD_PROFILE,
    PROFILE_STATUS_ENABLED,
    PROFILE_STATUS_GDPR,
    PROFILE_STATUS_GHOST,
    PROFILE_STATUS_NEW_IMPORT,
    PROFILE_STATUS_ON_HOLD,
    PROFILE_STATUS_UNTOUCHED,
    WEBSITE_REGEX,
} from '@advanza/constants'
import { Col, Row } from '@advanza/grid'
import { ActionModal } from '@advanza/modal'
import { changeEntity } from '@advanza/redux_entity'
import {
    Button,
    Divider,
    FixedWarningPopup,
    HoverText,
    Icon,
    LoadingDots,
    PreIcon,
} from '@advanza/ui'
import AutoRow from 'components/misc/AutoRow'
import ProvidersBanRestoreButton from 'components/providersBans/ProvidersBanRestoreButton'
import SearchLocationInput from 'components/ui/SearchLocationInput'
import { getDate, isFromDatabase } from 'date'
import { format, isPast, isValid } from 'date-fns'
import { hasAccess } from 'misc/user'
import { saveProvider } from '../../actions/providers'
import EntityComponentContainer from '../../containers/EntityComponentContainer'
import EntityComponent from '../services/EntityComponent'
import PhoneRouting from './PhoneRouting'
import moduleStyle from './providerBusinessInfoEntity.module.css'

class ProviderBusinessInfoEntity extends EntityComponent {
    addCtlEnterSave = {
        onKeyDown: (event) => event.ctrlKey && event.key === 'Enter' && this.save(),
    }

    constructor(props) {
        super(props)
        this.state = {
            allowPhoneRoutingEdit: false,
            gdprModalOpen: false,
            quarantineModalOpen: false,
        }
        this.onQuarantine = this.onQuarantine.bind(this)
    }

    togglePhoneRouteEdit() {
        let toggled = !this.state.allowPhoneRoutingEdit
        this.setState({ allowPhoneRoutingEdit: toggled })
        this.onChangeEntity({ _allowPhoneRoutingEdit: toggled })
    }

    onQuarantine() {
        const { entityId, storeBanEntity, invalidateProvidersBans } = this.props
        return call(`office/providers-bans/add-provider-to-bans/${entityId}`).then(({ ban }) => {
            this.onChangeEntity({
                bans: [ban.ban_id],
                status: 3,
            })
            storeBanEntity(ban)
            invalidateProvidersBans()
        })
    }

    editFields() {
        const entity = this.getEntity()
        const statusOptions =
            entity.status === PROFILE_STATUS_GHOST && !hasAccess('sales')
                ? [{ title: <Icon blue name="👻" />, value: PROFILE_STATUS_GHOST }]
                : [
                      { title: <Icon green name="power" />, value: PROFILE_STATUS_ENABLED },
                      { title: <Icon red name="power_off" />, value: PROFILE_STATUS_DISABLED },
                      {
                          title: <Icon red name="thumb_down_alt" />,
                          value: PROFILE_STATUS_DISABLED_BAD_PROFILE,
                      },
                      { title: <Icon red name="pause_circle" />, value: PROFILE_STATUS_ON_HOLD },
                      { title: <Icon orange name="pending" />, value: PROFILE_STATUS_UNTOUCHED },
                      {
                          title: <Icon style={{ color: 'purple' }} name="delete_forever" />,
                          value: PROFILE_STATUS_GDPR,
                      },
                      { title: <Icon blue name="👻" />, value: PROFILE_STATUS_GHOST },
                      { title: <Icon orange name="fiber_new" />, value: PROFILE_STATUS_NEW_IMPORT },
                      { title: <Icon red name="stop_circle" />, value: PROFILE_STATUS_CHURNED },
                  ]
        return {
            business_name: {
                type: 'geo',
                msg: 'Business name',
                map: {
                    street_number: 'street_number',
                    postal_code: 'postalcode',
                    route: 'street',
                    formatted_phone_number: 'phone',
                    website: 'website',
                    name: 'business_name',
                },
                validator: this.textValidator,
                ...this.addCtlEnterSave,
            },
            profile_url: {
                type: 'string',
                placeholder: 'Profile url',
                validator: (value) => {
                    return (
                        /*
                          This regex will match:
                        - Lowercase letters
                        - Numbers
                        - A range of UTF-8 characters
                        - Underscore
                        - Dash
                        * */
                        value && value.match(/^[a-z0-9_\-\p{Ll}]+$/gu)
                    )
                },
                defaultErrorMsg:
                    'profile url is required and can only contain letters, numbers and -',
            },
            phone: { type: 'phone', msg: 'Phone', ...this.addCtlEnterSave },
            secondary_phone: { type: 'phone', msg: 'Secondary phone', ...this.addCtlEnterSave },
            phone_routing_nr: { type: 'phone', msg: 'Phone routing nr' },
            use_phone_routing: { type: 'boolean', msg: 'use phone routing' },
            has_gmb: { type: 'boolean', msg: 'GMB claimed' },
            has_facebook: { type: 'boolean', msg: 'Has Facebook' },
            show_expert_blogs: { type: 'boolean', msg: 'Show expert blogs' },

            use_active_campaign: { type: 'boolean', msg: 'Use active campaign' },
            status: {
                type: 'select',
                className: 'select-min select-stealth',
                options: statusOptions,
                ...this.addCtlEnterSave,
            },
            email: { type: 'email', msg: 'Email', ...this.addCtlEnterSave },
            street_number: { type: 'text', msg: 'street nr', ...this.addCtlEnterSave },
            street_number_extra: { type: 'text', msg: 'street nr extra', ...this.addCtlEnterSave },
            street: { type: 'text', msg: 'Street', ...this.addCtlEnterSave },
            postalcode: { type: 'text', msg: 'Postal code', ...this.addCtlEnterSave },
            gmb_url: { type: 'text', msg: 'GMB url', ...this.addCtlEnterSave },
            facebook_url: { type: 'text', msg: 'Facebook url', ...this.addCtlEnterSave },
            linkedin_url: { type: 'text', msg: 'LinkedIn url', ...this.addCtlEnterSave },
            ...(entity.country_code.toUpperCase() === 'DE'
                ? {
                      xing_url: {
                          type: 'text',
                          msg: 'Xing url',
                          ...this.addCtlEnterSave,
                          validator: (val) =>
                              !val || val.indexOf('https://www.xing.com/pages/') === 0,
                          defaultErrorMsg: 'Has to start with https://www.xing.com/pages/',
                      },
                  }
                : {}),
            nr_employees: {
                type: 'number',
                msg: 'Nr. employees',
                ...this.addCtlEnterSave,
            },
            kvk: { type: 'text', msg: 'kvk', ...this.addCtlEnterSave },
            line_creative: {
                msg: 'Line creative',
                validator: (val) => !val || val.length <= 200,
                ...this.addCtlEnterSave,
            },
            established: {
                type: 'text',
                msg: 'Established on:',
                inputPlaceholder: 'yyyy-mm-dd',
                ...this.addCtlEnterSave,
                validator: (val) =>
                    !val || val.length === 0 || (isValid(new Date(val)) && isPast(new Date(val))),
            },
            claimed: {
                type: 'text',
                msg: 'Claimed on:',
                inputPlaceholder: 'yyyy-mm-dd hh:mm:ss',
                ...this.addCtlEnterSave,
                validator: (val) =>
                    !val || val.length === 0 || (isValid(new Date(val)) && isPast(new Date(val))),
                valueFormatter: (val) =>
                    isFromDatabase(val) ? format(getDate(val), 'yyyy-MM-dd HH:mm:ss') : val,
            },
            business_keywords: { type: 'text', msg: 'keyword list', ...this.addCtlEnterSave },
            years_experience: {
                type: 'number',
                msg: 'Years experience',
                ...this.addCtlEnterSave,
            },
            country_code: {
                type: 'select',
                className: 'select-min',
                placeholder: 'Country',
                options: [
                    { value: 'NL', title: 'NL' },
                    { value: 'BE', title: 'BE' },
                ],
            },
            website: {
                type: 'url',
                msg: 'Website url',
                ...this.addCtlEnterSave,
                validator: (val) => !val || val.match(WEBSITE_REGEX),
            },
            description: {
                type: 'textArea',
                msgid: 'Description',
                ...this.addCtlEnterSave,
            },
            external_id: {
                before: <img height={21} src="https://www.advanza.nl/favicon.ico" />,
                placeholder: 'Advanza id',
                style: { maxWidth: 140 },
                ...this.addCtlEnterSave,
            },
            unlinked_from_advanza: { type: 'date', msg: 'Unlink from advanza' },
            hide_place: { type: 'boolean', msg: 'Hide place' },
        }
    }

    save(params, doCheck = true) {
        const { entity } = this.props

        if (
            doCheck &&
            entity._beforeSave &&
            entity._beforeSave.status !== 4 &&
            entity.status === 4
        ) {
            this.setState({ gdprModalOpen: true })
            return Promise.resolve()
        }

        return super.save(params).then(
            () => {
                this.setState({ popupSaveSuccess: Date.now() })
            },
            (response) => {
                this.setState({
                    popupSaveError: Date.now(),
                    saveError: response,
                })
            }
        )
    }

    render() {
        const { entityId, entity } = this.props
        const { allowPhoneRoutingEdit, gdprModalOpen, quarantineModalOpen } = this.state
        const href =
            entity.website?.indexOf('//') !== -1 ? entity.website : `http://${entity.website}`
        const postalCodeDigits = entity.postalcode && entity.postalcode.replace(/\D/g, '')
        return (
            <div>
                <ActionModal
                    cancelText="No, don't delete"
                    text="Yes, delete account"
                    func={() => this.save({}, false).finally(() => window.location.reload())}
                    header="Delete account - Are you sure?"
                    body="This account will be deleted. Only company name, kvk, and address will be kept."
                    isOpen={gdprModalOpen}
                    setIsOpen={(gdprModalOpen) => this.setState({ gdprModalOpen })}
                    isSaving={entity._saving}
                />
                <FixedWarningPopup
                    timeout={false}
                    icon={<LoadingDots color="#fff" />}
                    fixed
                    show={entity._saving}>
                    Saving business info
                </FixedWarningPopup>
                <FixedWarningPopup green icon="check" fixed show={this.state.popupSaveSuccess}>
                    Saved !
                </FixedWarningPopup>
                <FixedWarningPopup
                    timeout={5000}
                    red
                    fixed
                    show={!entity._saving && this.state.popupSaveError}>
                    <pre>{JSON.stringify(this.state.saveError, null, 2)}</pre>
                </FixedWarningPopup>
                <Row middle="xs" between="xs">
                    <Col x>
                        <Divider />
                        {this.renderInput('external_id')}
                    </Col>
                    {entity.external_id && (
                        <Col x>
                            <a
                                target="_blank"
                                href={`https://leads.advanza.nl/Company/wrapper?company_id=${entity.external_id}`}>
                                <Icon name="open_in_new" /> To advanza
                            </a>
                        </Col>
                    )}
                    {entity.external_id && (
                        <Col x>
                            {entity.unlinked_from_advanza && (
                                <div>Unlinked from advanza on {entity.unlinked_from_advanza}</div>
                            )}
                        </Col>
                    )}
                    <Button disabled={!entity._isTouched} onClick={this.save}>
                        <PreIcon icon="save" center>
                            {entity._saving ? <LoadingDots color="#fff" /> : 'Save'}
                        </PreIcon>
                    </Button>
                </Row>
                <Divider m />
                {this.renderInput('business_name')}
                <Divider m />
                {this.renderInput('profile_url')}
                <Divider m />
                <Row middle="xs">
                    <Col xs>{this.renderInput('kvk')}</Col>
                    <Col x>
                        <a
                            target="_blank"
                            href={`https://www.graydongo.nl/zoekresultaten?q=${entity.business_name}`}>
                            <Icon name="search" />
                        </a>
                    </Col>
                </Row>
                <Divider m />
                <Row between="xs">
                    <Col x>{this.renderInput('claimed')}</Col>
                    <Col x>
                        {(entity.bans || []).length ? (
                            <ProvidersBanRestoreButton providerId={entityId} />
                        ) : (
                            <Button onClick={() => this.setState({ quarantineModalOpen: true })}>
                                <Icon name="coronavirus" /> Quarantine provider
                            </Button>
                        )}
                        <ActionModal
                            cancelText="No"
                            text="Yes"
                            func={this.onQuarantine}
                            header="Quarantine provider, are you sure?"
                            isOpen={quarantineModalOpen}
                            setIsOpen={(quarantineModalOpen) =>
                                this.setState({ quarantineModalOpen })
                            }
                        />
                    </Col>
                    <Col x>
                        {entity.franchise_status ? (
                            <HoverText
                                trigger={
                                    <Icon
                                        name="layers"
                                        style={{ color: 'rgb(100,100,100)', marginTop: 12 }}
                                    />
                                }>
                                This account represents a whole franchise, not a single provider.
                                Its profile is never published.
                            </HoverText>
                        ) : (
                            this.renderInput('status')
                        )}
                    </Col>
                </Row>
                <Divider m />
                <Row middle="xs">
                    <Col xs>{this.renderInput('phone')}</Col>
                    <Col xs>{this.renderInput('secondary_phone')}</Col>
                </Row>
                <Divider m />
                <Row middle="xs">
                    <button onClick={() => this.togglePhoneRouteEdit()}>
                        <Icon name={allowPhoneRoutingEdit ? 'lock_open' : 'lock'} />
                    </button>
                    <Col xs>
                        <div id="phone_routing_nr">
                            <span className={allowPhoneRoutingEdit ? '' : moduleStyle.noEdit}>
                                {this.renderInput('phone_routing_nr')}
                            </span>
                            <Divider />
                            <AutoRow>
                                <PhoneRouting change={this.onChangeEntity} entity={entity} />
                                {['DELETED', 'PENDING', 'REQUESTED'].filter((sub) =>
                                    entity.phone_routing_nr?.includes(sub)
                                ).length === 0 && (
                                    <Col x>{this.renderInput('use_phone_routing')}</Col>
                                )}
                            </AutoRow>
                        </div>
                    </Col>
                </Row>
                <Divider m />
                <Row middle="xs">
                    <Col xs>{this.renderInput('email')}</Col>
                    <Col x>{this.renderInput('website')}</Col>
                    <Col x>
                        <a href={href} target="_blank">
                            <Icon>link</Icon>
                        </a>
                    </Col>
                </Row>
                <Divider m />
                <Row middle="xs">
                    <Col xs>{this.renderInput('nr_employees')}</Col>
                    <Col xs>{this.renderInput('established')}</Col>
                    <Col xs>{this.renderInput('years_experience')}</Col>
                </Row>
                <Divider m />
                {this.renderInput('business_keywords')}
                <Divider m />
                <Row middle="xs">
                    <Col xs={6}>{this.renderInput('street')}</Col>
                    <Col xs={3}>{this.renderInput('street_number')}</Col>
                    <Col xs={3}>{this.renderInput('street_number_extra')}</Col>
                    <Col xs={3}>{this.renderInput('hide_place')}</Col>
                </Row>
                <Divider m />
                <Row middle="xs" style={{ flexWrap: 'nowrap' }}>
                    <Col x>{this.renderInput('postalcode')} </Col>
                    <Col x>
                        <SearchLocationInput
                            placeholder="City"
                            switchPlaceholder
                            fixedQuery={postalCodeDigits}
                            countryCode={entity.country_code}
                            value={entity && entity.cityName}
                            limit={99}
                            onChangeLocation={(place) => {
                                this.onChangeEntity({
                                    postal_code_id: place.postal_code_id,
                                    cityName: place.place_name,
                                })
                            }}
                        />
                    </Col>
                    <Col x>
                        <Section w>{this.renderInput('country_code')}</Section>{' '}
                    </Col>
                </Row>
                {this.renderInput('description')}
                <Divider m />
                {this.renderInput('line_creative')}
                <small style={{ color: entity.line_creative.length > 200 && 'red' }}>
                    {entity.line_creative.length}/200
                </small>
                <Divider m />
                <Row middle="xs">
                    <Col xs>{this.renderInput('has_gmb')}</Col>
                    <Col xs>{this.renderInput('has_facebook')}</Col>
                </Row>
                <Divider m />
                <Row middle="xs">
                    <Col xs>{this.renderInput('gmb_url')}</Col>
                    <Col xs>{this.renderInput('facebook_url')}</Col>
                </Row>
                <Divider m />
                <Row middle="xs">
                    <Col xs>{this.renderInput('linkedin_url')}</Col>
                    <Col xs>
                        {entity.country_code.toUpperCase() === 'DE' && this.renderInput('xing_url')}
                    </Col>
                </Row>
                <Divider m />
                <Row middle="xs">
                    <Col xs>{this.renderInput('use_active_campaign')}</Col>
                    <Col xs>{this.renderInput('show_expert_blogs')}</Col>
                </Row>
                <Divider m />
                <Row end="xs">
                    <Col x>
                        <Button disabled={!entity._isTouched} onClick={this.save}>
                            {entity._saving ? 'saving..' : 'Save'}
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default EntityComponentContainer(ProviderBusinessInfoEntity, {
    store: 'providers',
    name: 'providers',
    saveFunc: saveProvider,
    mapDispatchToProps: (dispatch) => ({
        storeBanEntity: (ban) =>
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'bans',
                    key: ban.ban_id,
                    diff: ban,
                })
            ),
        invalidateProvidersBans: () => dispatch({ type: 'INVALIDATE_PROVIDERSBANS' }),
    }),
})
