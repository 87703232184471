import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { Checkbox, InputField } from '@advanza/input'
import { changeEntity } from '@advanza/redux_entity'
import { Button, Divider } from '@advanza/ui'
import {
    ChannableFeedService,
    updateSelectedPart,
} from 'components/channableFeed/ChannableFeedList'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import Option from 'interfaces/Options'
import Question from 'interfaces/Questions'
import { Fragment, useState } from 'react'

interface Props {
    serviceId: number
}

const ChannableFeedEntity = ({ serviceId }: Props) => {
    const dispatch = useAppDispatch()
    const {
        entities: { services, questions, options },
    } = useAppSelector((state) => state.channableFeed)
    const service: ChannableFeedService = services[serviceId]
    const [newSlugs, setNewSlugs] = useState<{ [optionId: number]: string }>({})

    return (
        <>
            {service.questions.map((questionId, questionIndex) => {
                const question: Question = questions[questionId]

                return !question.options.length ? null : (
                    <Fragment key={questionId}>
                        {0 < questionIndex && <Divider m />}
                        <b>{question.question_name_pro}</b>{' '}
                        <span style={{ color: '#aaa', fontSize: 10 }}>(#{questionId})</span>
                        <Divider s />
                        {question.options.map((optionId) => {
                            const option: Option = options[optionId]

                            return (
                                <Row key={optionId} middle="xs">
                                    <Col x>
                                        <Checkbox
                                            value={option.channable}
                                            onChange={(e) => {
                                                dispatch(
                                                    changeEntity({
                                                        store: 'channableFeed',
                                                        name: 'options',
                                                        key: optionId,
                                                        diff: { channable: e.target.value },
                                                    })
                                                )
                                                call(`office/services/save-option/${optionId}`, {
                                                    json: { channable: e.target.value },
                                                })
                                                // @ts-ignore
                                                dispatch(updateSelectedPart(serviceId))
                                            }}
                                        />
                                    </Col>
                                    <Col x>
                                        {option.value}{' '}
                                        <span style={{ color: '#aaa', fontSize: 10 }}>
                                            (#{optionId})
                                        </span>
                                    </Col>
                                    {option.channable && !option.slug && (
                                        <>
                                            {/*@ts-ignore*/}
                                            <Col x style={{ color: 'red' }}>
                                                needs slug:
                                            </Col>
                                            <Col x>
                                                <Divider xs />
                                                {/*@ts-ignore*/}
                                                <InputField
                                                    inputPlaceholder="slug"
                                                    value={newSlugs[optionId] || ''}
                                                    onChange={(e: any) =>
                                                        setNewSlugs((newSlugs) => ({
                                                            ...newSlugs,
                                                            [optionId]: e.target.value.replace(
                                                                /\s/g,
                                                                ''
                                                            ),
                                                        }))
                                                    }
                                                />
                                                <Divider xs />
                                            </Col>
                                            <Col x>
                                                <Button
                                                    disabled={!newSlugs[optionId]}
                                                    onClick={() => {
                                                        dispatch(
                                                            changeEntity({
                                                                store: 'channableFeed',
                                                                name: 'options',
                                                                key: optionId,
                                                                diff: { slug: newSlugs[optionId] },
                                                            })
                                                        )
                                                        call(
                                                            `office/services/save-option/${optionId}`,
                                                            {
                                                                json: { slug: newSlugs[optionId] },
                                                            }
                                                        )
                                                    }}>
                                                    Save
                                                </Button>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            )
                        })}
                    </Fragment>
                )
            })}
        </>
    )
}

export default ChannableFeedEntity
