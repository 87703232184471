import { Col, Row } from '@advanza/grid'
import { Toggle } from '@advanza/input'
import Modal from '@advanza/modal'
import { Button, Divider, Ellipsis, HoverText, Icon, PreIcon } from '@advanza/ui'
import NiceModal from '@ebay/nice-modal-react'
import AutoRow from 'components/misc/AutoRow'
import PremiumCancelModal from 'components/providers/PremiumCancelModal'
import { getDate } from 'date'
import format from 'date-fns/format'
import LoadingDotsTeam from 'misc/LoadingDotsTeam'
import React, { Fragment } from 'react'
import {
    CANCEL_MODE_SILENT,
    fetchProvider,
    saveApi,
    saveLeadSetting,
    unsubscribe,
} from '../../actions/providers'
import EntityComponentContainer from '../../containers/EntityComponentContainer'
import EntityComponent from '../services/EntityComponent'
import LimitReachedLabel from './LimitReachedLabel'

class LeadSettings extends EntityComponent {
    constructor() {
        super()
        this.state = {
            warningMsg: null,
        }
    }

    editFields = () => {
        const entity = this.getEntity()
        return {
            max_day: {
                type: 'number',
                msg: 'D',
                before: entity.max_day === 0 && <Icon name="all_inclusive" />,
                validator: (val) => val === 0 || val < 999,
                inputStyle: {
                    minWidth: 50,
                    color: entity.max_day === 0 && 'grey',
                },
            },
            max_week: {
                type: 'number',
                msg: 'W',
                before: entity.max_week === 0 && <Icon name="all_inclusive" />,
                validator: (val) => val === 0 || val < 999,
                inputStyle: {
                    minWidth: 50,
                    color: entity.max_week === 0 && 'grey',
                },
            },
            max_month: {
                type: 'number',
                msg: 'M',
                before: entity.max_month === 0 && <Icon name="all_inclusive" />,
                validator: (val) => val === 0 || val < 999,
                inputStyle: {
                    minWidth: 50,
                    color: entity.max_month === 0 && 'grey',
                },
            },
            website_clicks_max_week: {
                type: 'number',
                msg: 'Website ',
                before: entity.website_clicks_max_week === 0 && <Icon name="all_inclusive" />,
                validator: (val) => val === 0 || val < 999,
                inputStyle: {
                    minWidth: 50,
                    color: entity.website_clicks_max_week === 0 && 'grey',
                },
            },
            charge_website_clicks: {
                type: 'boolean',
                msg: <Ellipsis>charge website clicks</Ellipsis>,
            },
            charge_phone_calls: {
                type: 'boolean',
                msg: <Ellipsis>charge phone calls</Ellipsis>,
            },
            extended_match_details: {
                type: 'boolean',
                msg: (
                    <HoverText enableClick fontSize={18} trigger={'Extended details'}>
                        Show real email and full phonenumber in lead mails
                    </HoverText>
                ),
            },
        }
    }

    render() {
        const {
            entity = {},
            entities: { providersApis = {}, apis = {} } = {},
            provider = {},
            logs = {},
            saveApi,
            unsubscribe,
        } = this.props
        const { _saving, _isTouched } = entity
        const logArr = (provider.logs || [])
            .map((logId) => logs[logId])
            .filter((log) => log)
            .filter((log) => log.log_type === 'subscribe' || log.log_type === 'unsubscribe')
            .sort((logA, logB) => logA.log_id - logB.log_id)
        const hasFreeWebsiteClicks =
            entity.charge_website_clicks &&
            provider.link_exchange_widget &&
            provider.link_exchange_widget.active
        const phoneRoutingNrRequested = provider.phone_routing_nr?.includes('REQUESTED')
        const onSave = () =>
            this.save().then(({ warningMsg }) => {
                if (warningMsg) {
                    this.setState({ warningMsg })
                }
            })

        return (
            <div>
                <Modal
                    maxWidth={750}
                    open={this.state.warningMsg}
                    close={() => this.setState({ warningMsg: null })}>
                    <h1>Warning!</h1>
                    <p>{this.state.warningMsg}</p>
                    <LoadingDotsTeam
                        imgSrc={'https://static.trustoo.nl/team/white/Pieter.jpg'}
                        size={230}
                    />
                </Modal>
                <AutoRow noWrap>
                    {this.renderInput('max_day')}
                    {this.renderInput('max_week')}
                    {this.renderInput('max_month')}
                    {this.renderInput('website_clicks_max_week')}
                    <AutoRow>
                        <LimitReachedLabel leadSetting={entity} />
                    </AutoRow>
                </AutoRow>
                {provider.isFreeTrial && (
                    <>
                        <Divider s />
                        <small>
                            <i>
                                During the free trial period, the day and month limits are overruled.
                                The day limit is always 1 and there is no month limit, but a free trial lead limit.
                            </i>
                        </small>
                    </>
                )}
                <Divider m />
                <AutoRow>
                    {!entity.signing_date && !entity.cancel_signing_date && (
                        <Button
                            name="text"
                            onClick={() =>
                                this.onChangeEntity({
                                    signing_date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
                                    cancel_signing_date: null,
                                    unsubscribed: null,
                                })
                            }>
                            Agreement signed
                        </Button>
                    )}
                    {entity.cancel_signing_date && (
                        <Button
                            name="text"
                            onClick={() =>
                                this.onChangeEntity({
                                    signing_date: entity.cancel_signing_date,
                                    cancel_signing_date: null,
                                    unsubscribed: null,
                                })
                            }>
                            Restore Premium
                        </Button>
                    )}
                    {(entity.signing_date || entity.cancel_signing_date) && (
                        <AutoRow>
                            <HoverText
                                enableClick
                                trigger={
                                    <PreIcon name="contact_mail">
                                        {format(
                                            getDate(
                                                entity.signing_date || entity.cancel_signing_date
                                            ),
                                            'yyyy-MM-dd'
                                        )}
                                    </PreIcon>
                                }>
                                Client has signed the agreement
                            </HoverText>
                            <button
                                onClick={() =>
                                    entity.signing_date
                                        ? NiceModal.show(PremiumCancelModal, {
                                              providerId: entity.service_provider_id,
                                          })
                                        : unsubscribe(
                                              entity.service_provider_id,
                                              CANCEL_MODE_SILENT,
                                              null
                                          )
                                }>
                                <Icon name="clear" />
                            </button>
                        </AutoRow>
                    )}
                </AutoRow>
                <Divider m />
                <AutoRow>
                    {entity.signing_date && (
                        <HoverText
                            trigger={
                                <AutoRow>
                                    <span
                                        style={
                                            phoneRoutingNrRequested
                                                ? {
                                                      filter: 'hue-rotate(270deg) saturate(80%) brightness(120%)',
                                                  }
                                                : {}
                                        }>
                                        {this.renderInput('charge_phone_calls')}
                                    </span>
                                    {hasFreeWebsiteClicks ? (
                                        <b style={{ color: '#ed7d31' }}>
                                            Free website clicks (link exchange)
                                        </b>
                                    ) : (
                                        this.renderInput('charge_website_clicks')
                                    )}
                                    {this.renderInput('extended_match_details')}
                                </AutoRow>
                            }>
                            {phoneRoutingNrRequested && (
                                <>
                                    Customer requested phone leads but profile status is not enabled
                                    yet
                                </>
                            )}
                        </HoverText>
                    )}
                    {(_isTouched || _saving) && (
                        <Button onClick={onSave} disabled={!_isTouched}>
                            {_saving ? 'saving..' : 'Save'}
                        </Button>
                    )}
                </AutoRow>
                {(provider.active_providers_apis || [])
                    .map((providerApiId) => apis[providersApis[providerApiId].api])
                    .filter((api) => api)
                    .map((api) => (
                        <>
                            <Divider m />
                            <Row middle="xs">
                                <Col x style={{ color: '#2ece40', fontWeight: 700 }}>
                                    API active: {api.apiName}
                                </Col>
                                <Col x>Leads via mail:</Col>
                                <Col x>
                                    <Toggle
                                        name="always_mail_lead"
                                        value={api.always_mail_lead}
                                        textTrue="True"
                                        textFalse="False"
                                        onChange={() => {
                                            this.onChangeOtherEntity({
                                                store: 'providers',
                                                name: 'apis',
                                                key: api.api_id,
                                                diff: { always_mail_lead: !api.always_mail_lead },
                                            })
                                            saveApi(api.api_id)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </>
                    ))}
                {logArr.length > 0 && <Divider m />}
                {logArr.map((log, i) => (
                    <AutoRow>
                        {format(getDate(log.created), 'dd/MM/yyyy')}
                        {logArr[i + 1]
                            ? ' - ' + format(getDate(logArr[i + 1].created), 'dd/MM/yyyy')
                            : ''}
                        {log.log_type === 'subscribe' ? (
                            <span style={{ color: 'rgb(12,214,5)' }}>Active</span>
                        ) : (
                            <span style={{ color: 'rgb(255,0,0)' }}>Unsubscribed</span>
                        )}
                    </AutoRow>
                ))}
            </div>
        )
    }
}

export default EntityComponentContainer(LeadSettings, {
    store: 'providers',
    name: 'leadSettings',
    saveFunc: saveLeadSetting,
    mapDispatchToProps: (dispatch) => ({
        saveApi: (apiId) => dispatch(saveApi(apiId)),
        fetchProvider: (providerId, forceFetch) => dispatch(fetchProvider(providerId, forceFetch)),
        unsubscribe: (providerId, mode, reason) => dispatch(unsubscribe(providerId, mode, reason)),
    }),
})
