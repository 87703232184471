export function changeFavicon() {
    if (window.location.hostname === 'testoffice.trustoo.nl' || window.location.hostname === 'l.office.trustoo.nl') {
        const link = document.createElement('link')
        link.rel = 'icon'
        link.href = 'https://static.trustoo.nl/app/dev/icons/favicon.ico'
        document.head.appendChild(link)
        const link2 = document.createElement('link')
        link2.rel = 'apple-touch-icon'
        link2.href = 'https://static.trustoo.nl/app/dev/icons/apple-touch-icon.png'
        document.head.appendChild(link2)
        const link3 = document.createElement('link')
        link3.rel = 'mask-icon'
        link3.href = 'https://static.trustoo.nl/app/dev/icons/safari-pinned-tab.svg'
        link3.color = '#5bbad5'
        document.head.appendChild(link3)
        const link4 = document.createElement('link')
        link4.rel = 'manifest'
        link4.href = 'https://static.trustoo.nl/app/dev/icons/site.webmanifest'
        document.head.appendChild(link4)
        const link5 = document.createElement('meta')
        link5.name = 'msapplication-TileColor'
        link5.content = '#da532c'
        document.head.appendChild(link5)
        const link6 = document.createElement('meta')
        link6.name = 'theme-color'
        link6.content = '#ffffff'
        document.head.appendChild(link6)

    }
}