import { Icon } from '@advanza/ui'
import { useHistoryProfileTabs } from 'hooks/miscHooks'
import React from 'react'
import { Link } from 'react-router-dom'
import style from './profileTabs.module.css'

const ProfileTabs = () => {
    const { deleteTab, addData, getTabs, doUpdate } = useHistoryProfileTabs()

    if (!localStorage.getItem('showTabs')) {
        return (
            <button
                onClick={() => {
                    localStorage.setItem('showTabs', 'true')
                    doUpdate()
                }}>
                <small>use tabs</small>
            </button>
        )
    }
    return (
        <div className={style.root}>
            {getTabs(true).map((tab) => {
                const isActive = tab.url === window.location.pathname
                return (
                    <div
                        title={tab.provider.service_provider_id}
                        className={[style.item, isActive ? style.active : ' '].join(' ')}
                        key={tab.url}>
                        <Link to={tab.url}>
                            <span>{tab.provider.business_name}</span>

                            <button
                                className={tab.isPinned ? style.pinned : style.notPinned}
                                onClick={(e) => {
                                    e.preventDefault()
                                    addData(tab.provider.service_provider_id, {
                                        isPinned: tab.isPinned !== true,
                                    })
                                }}>
                                <Icon name="push_pin" />
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    deleteTab(tab.provider.service_provider_id)
                                }}>
                                <Icon name="close" />
                            </button>
                        </Link>
                    </div>
                )
            })}
            <button
                className={style.hideButton}
                onClick={() => {
                    localStorage.removeItem('showTabs')
                    doUpdate()
                }}>
                <Icon name="visibility_off" />
            </button>
        </div>
    )
}

export default ProfileTabs
