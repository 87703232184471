import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Alert, WithIcon } from '@advanza/advanza_generic'
import { Button, Icon, LoadingDots } from '@advanza/ui'
import BodyPortal from 'components/ui/BodyPortal'
import style from './safeButton.module.css'
import PropTypes from 'prop-types'

const SafeButton = ({
    action,
    buttonText,
    cancelText,
    confirmText = 'Confirm',
    confirmMessage = <b className={style.confirmButton}>{confirmText}</b>,
    successMessage,
    hidden,
    callback,
    tagName = 'div',
    hideOnFinish = false,
    newStyle = false,
    textButton = false,
    className = undefined,
    useErrorPopup = undefined,
}) => {
    const [showConfirm, setShowConfirm] = useState(false)
    const [actionCompleted, setActionCompleted] = useState(false)
    const [startHiding, setStartHiding] = useState(false)
    const [isHidden, setIsHidden] = useState(hidden)
    const [popup, setPopup] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const ref = useRef(null)

    const onBodyClick = (e) => {
        if (
            ref.current &&
            e.target !== ref.current &&
            !ref.current.contains(e.target) &&
            showConfirm
        ) {
            setShowConfirm(false)
        }
    }

    useEffect(() => {
        document.body.addEventListener('click', onBodyClick)

        return () => {
            document.body.removeEventListener('click', onBodyClick)
        }
    }, [showConfirm])

    const onError = (response) => {
        console.log(response)
        if (response && (response.error === 'popup' || useErrorPopup)) {
            const closePopup = () => {
                setPopup(false)
                setError(false)
            }
            setPopup(
                <Alert
                    title={<WithIcon icon="warning">Error</WithIcon>}
                    closeOnOutsideClick
                    close={closePopup}>
                    <p className="fsize lg">
                        {response.msg || response.error || JSON.stringify(response, null, 2)}
                    </p>
                </Alert>
            )
        }
        setError(true)
        setLoading(false)
    }

    const onClick = async (e) => {
        if (loading || actionCompleted) {
            return
        }

        if (!showConfirm) {
            setShowConfirm(true)
        } else {
            setLoading(true)

            try {
                const response = await action()
                callback && callback(response)
                setLoading(false)
                setActionCompleted(true)
                setTimeout(() => setStartHiding(true), 100)
                setTimeout(() => setIsHidden(true), 200)
            } catch (error) {
                onError(error)
            }
        }
    }

    const onCancel = (e) => {
        e.stopPropagation()
        setShowConfirm(false)
    }

    const renderButtonContent = () => {
        if (error) {
            return <div>Error!</div>
        }
        if (actionCompleted) {
            return <div>{successMessage || 'Success!'}</div>
        }
        if (loading) {
            return <LoadingDots />
        }
        if (showConfirm) {
            return <div>{confirmMessage}</div>
        } else {
            return false
        }
    }

    if (isHidden) {
        return null
    }

    const showCancelButton = cancelText !== false && showConfirm && !actionCompleted && !loading
    const classString = [
        className || '',
        showConfirm ? style.warning : '',
        startHiding && hideOnFinish ? style.animateOut : '',
        loading ? style.isLoading : '',
        actionCompleted ? style.done : '',
    ].join(' ')

    const buttonContent = renderButtonContent()
    const ButtonType = newStyle || textButton ? Button : 'button'
    return React.createElement(
        tagName,
        { ref: ref, className: style.root },
        <Fragment>
            <ButtonType
                key={1}
                name={textButton && 'text'}
                style={!buttonContent ? {} : { display: 'none' }}
                onClick={onClick}
                className={classString}>
                {buttonText}
            </ButtonType>
            <ButtonType
                key={2}
                style={!buttonContent ? { display: 'none' } : {}}
                name={textButton && 'text'}
                onClick={onClick}
                className={classString}>
                {buttonContent}
            </ButtonType>
            <ButtonType
                key={3}
                className={style.cancelButton}
                style={showCancelButton ? {} : { display: 'none' }}
                onClick={onCancel}
                name={newStyle ? newStyle.toString() : textButton ? 'text' : undefined}>
                {cancelText || <Icon name="close" />}
            </ButtonType>
            {popup && <BodyPortal>{popup}</BodyPortal>}
        </Fragment>
    )
}

SafeButton.propTypes = {
    action: PropTypes.func,
    buttonText: PropTypes.any,
    cancelText: PropTypes.any,
    confirmMessage: PropTypes.any,
    hidden: PropTypes.bool,
    callback: PropTypes.func,
}

export default SafeButton
