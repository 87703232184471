import { createElement, CSSProperties } from 'react'
import css from './icon.module.css'
import { S3_PUBLIC } from '../constants'

interface Props {
    children?: string
    name?: string
    primColor?: boolean
    green?: boolean
    grey?: boolean
    red?: boolean
    orange?: boolean
    white?: boolean
    className?: string
    classNameIcon?: string
    style?: CSSProperties
    fontSize?: number
    s3?: boolean
    s3Dir?: string
    fileName?: string
    ext?: 'svg' | string
    theme?: 'outlined' | 'filled'

    [x: string]: any
}

const Icon = ({
    children = '',
    name = '',
    primColor = undefined,
    green = undefined,
    grey = undefined,
    red = undefined,
    orange = undefined,
    white = undefined,
    className = undefined,
    classNameIcon = undefined,
    style = {},
    fontSize = undefined,
    s3 = undefined,
    fileName = undefined,
    s3Dir = 'icon/pro',
    ext = 'svg',
    theme = 'outlined',
    ...rest
}: Props) => {
    const classNames = [
        'material-symbols-outlined',
        css.icon,
        (className && css[className]) || '',
        primColor ? css.colorPrimary : '',
        green ? css.green : '',
        grey ? css.grey : '',
        red ? css.red : '',
        orange ? css.orange : '',
        white ? css.colorWhite : '',
        theme == 'filled' ? css.fill : '',
        classNameIcon ? classNameIcon : '',
    ].join(' ')
    const extraStyle: CSSProperties = {}

    if (fontSize < 21) {
        extraStyle.transform = 'rotate(0.03deg)'
    }

    if (fontSize) {
        extraStyle.fontSize = fontSize
    }

    if (s3) {
        extraStyle.width = '1em'
    }

    const tagName = s3 ? 'img' : 'i'
    const extraProps = {
        ...rest,
        ...(s3 ? { src: `${S3_PUBLIC()}${s3Dir}/${fileName || name + '.' + ext}` } : {}),
    }

    return createElement(
        tagName,
        {
            className: classNames,
            ...extraProps,
            style: { ...extraStyle, ...style },
        },
        s3 ? undefined : name || children
    )
}

export default Icon
