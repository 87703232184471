import { createReducer, getUpdater } from '@advanza/advanza_generic'
import adminUsersReducer from 'reducers/adminUsersReducer'
import aggregatedReviewsReducer from 'reducers/aggregatedReviewsReducer'
import analyticsReducer from 'reducers/analyticsReducer'
import apisReducer from 'reducers/apisReducer'
import bansReducer from 'reducers/bansReducer'
import cancellationsReducer from 'reducers/cancellationsReducer'
import channableFeedReducer from 'reducers/channableFeedReducer'
import closedLoopReducer from 'reducers/closedLoopReducer'
import countryReducer from 'reducers/countryReducer'
import customPricesReducer from 'reducers/customPricesReducer'
import demandUsersReducer from 'reducers/demandUsersReducer'
import eventsReducer from 'reducers/eventsReducer'
import expectationSettingsReducer from 'reducers/expectationSettingsReducer'
import faqsReducer from 'reducers/faqsReducer'
import faqTopicsReducer from 'reducers/faqTopicsReducer'
import filesReducer from 'reducers/filesReducer'
import geoTargetingReducer from 'reducers/geoTargetingReducer'
import invoicesReducer from 'reducers/invoicesReducer'
import keywordsReducer from 'reducers/keywordsReducer'
import labelsReducer from 'reducers/labelsReducer'
import licencesReducer from 'reducers/licencesReducer'
import licencesUpdatersReducer from 'reducers/licencesUpdatersReducer'
import logsReducer from 'reducers/logsReducer'
import matchesReducer from 'reducers/matchesReducer'
import notesReducer from 'reducers/notesReducer'
import notificationsReducer from 'reducers/notificationsReducer'
import pauseReasonsReducer from 'reducers/pauseReasonsReducer'
import phoneClicksReducer from 'reducers/phoneClicksReducer'
import profileLabelsReducer from 'reducers/profileLabelsReducer'
import providersBansReducer from 'reducers/providersBansReducer'
import providersBuildersReducer from 'reducers/providersBuildersReducer'
import providersDatesReducer from 'reducers/providersDatesReducer'
import providersReducer from 'reducers/providersReducer'
import reclaimsReducer from 'reducers/reclaimsReducer'
import regionsReducer from 'reducers/regionsReducer'
import requestsReducer from 'reducers/requestsReducer'
import reviewsReducer from 'reducers/reviewsReducer'
import salesLeadsReducer from 'reducers/salesLeadsReducer'
import servicesReducer from 'reducers/servicesReducer'
import servicesStatsReducer from 'reducers/servicesStatsReducer'
import {
    changeEntity,
    changeFilter,
    invalidateFilters,
    receiveList,
} from 'reducers/sharedReducerHandlers'
import statusesReducer from 'reducers/statusesReducer'
import subscriptionsReducer from 'reducers/subscriptionsReducer'
import systemLogsReducer from 'reducers/systemLogsReducer'
import testimonialsReducer from 'reducers/testimonialsReducer'
import toastsReducer from 'reducers/toastsReducer'
import websiteClicksReducer from 'reducers/websiteClicksReducer'

const createListReducer = (reducerKey) => {
    const reducerKeyUpper = reducerKey.toUpperCase()
    const update = getUpdater()

    const handlers = {
        [reducerKeyUpper + '_RECEIVE_LIST']: receiveList,
        [reducerKeyUpper + '_CHANGE_FILTER']: changeFilter,
        ['CHANGE_ENTITY_' + reducerKeyUpper]: changeEntity,
        ['INVALIDATE_' + reducerKeyUpper]: invalidateFilters,
        [reducerKeyUpper + '_REQUEST']: (state) =>
            update(state, {
                isFetching: { $set: true },
            }),
    }

    const initialState = {
        isFetching: false,
        didInvalidate: false,
        counts: {},
        entities: {},
        result: {},
        filters: {},
    }

    return createReducer(initialState, handlers)
}

// sort alphabetically for Redux DevTools
const rootReducer = {
    adminUsers: adminUsersReducer,
    aggregatedReviews: aggregatedReviewsReducer,
    analytics: analyticsReducer,
    apis: apisReducer,
    bannedRequests: createListReducer('bannedRequests'),
    bans: bansReducer,
    cancellations: cancellationsReducer,
    channableFeed: channableFeedReducer,
    closedLoop: closedLoopReducer,
    countries: countryReducer,
    customPrices: customPricesReducer,
    demandUsers: demandUsersReducer,
    events: eventsReducer,
    expectationSettings: expectationSettingsReducer,
    expertiseKeywords: createListReducer('expertiseKeywords'),
    faqs: faqsReducer,
    faqTopics: faqTopicsReducer,
    files: filesReducer,
    geoTargeting: geoTargetingReducer,
    invoices: invoicesReducer,
    keywords: keywordsReducer,
    labels: labelsReducer,
    licences: licencesReducer,
    licencesUpdaters: licencesUpdatersReducer,
    matches: matchesReducer,
    notes: notesReducer,
    notifications: notificationsReducer,
    pauseReasons: pauseReasonsReducer,
    phoneClicks: phoneClicksReducer,
    profileLabels: profileLabelsReducer,
    profileTabs: (state = {}, action) => {
        if (action.type === 'UPDATE_TABS') {
            return { ...state, updated: Date.now() }
        }
        return state
    },
    providers: providersReducer,
    providersBans: providersBansReducer,
    providersBuilders: providersBuildersReducer,
    providersDates: providersDatesReducer,
    reclaims: reclaimsReducer,
    regions: regionsReducer,
    requests: requestsReducer,
    reviews: reviewsReducer,
    salesLeads: salesLeadsReducer,
    services: servicesReducer,
    serviceStats: servicesStatsReducer,
    statuses: statusesReducer,
    subscriptions: subscriptionsReducer,
    testimonials: testimonialsReducer,
    websiteClicks: websiteClicksReducer,
    logs: logsReducer,
    system_logs: systemLogsReducer,
    toasts: toastsReducer,
}

export default rootReducer
