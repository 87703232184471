import { useEffect, useState } from 'react'
import * as ReactDOM from 'react-dom'
import style from './linearLoader.module.css'

interface Props {
    fixed?: boolean
    absolute?: boolean
    className?: string
}

const LinearLoader = ({ fixed, absolute, className }: Props) => {
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        setMounted(true)
    }, [])

    const canUseDOM = (): boolean => {
        return !!(typeof window !== 'undefined' && window.document && window.document.createElement)
    }

    if ((!canUseDOM() || !mounted) && fixed) {
        return <div></div>
    }

    const loader = (
        <div
            className={[
                style.linearProgress,
                fixed ? style.fixed : absolute ? style.absolute : '',
                className || '',
            ].join(' ')}>
            <div className={[style.bar, style.bar1].join(' ')} />
            <div className={[style.bar, style.bar2].join(' ')} />
        </div>
    )

    if (fixed) {
        return ReactDOM.createPortal(loader, document.getElementById('portal_1')!)
    } else {
        return loader
    }
}

export default LinearLoader
