import { Section } from '@advanza/advanza_generic'
import { Col, Row } from '@advanza/grid'
import { InputField } from '@advanza/input'
import { Icon, PreIcon } from '@advanza/ui'
import ServicesSelect from 'components/services/ServicesSelect'
import PropTypes from 'prop-types'
import queryString from 'querystring'
import React from 'react'
import FlexTable from './FlexTable/FlexTable'
import LinearLoader from './LinearLoader'
import ListItemSelector from './ListItemSelector'
import Pagination from './Pagination'
import Restricted from './Restricted'
import style from './tableList.module.css'

class TableList extends React.Component {
    constructor(props) {
        super(props)
        this.selectItems = this.selectItems.bind(this)
    }

    selectItems(ids) {
        const { select, filter } = this.props
        return select(ids, filter.filterId)
    }

    render() {
        const {
            changeFilter,
            pages = [[]],
            filter = {},
            count,
            isFetching,
            url,
            compact,
            hideHeader,
            selectable,
            rows: renderedRows = {},
            renderRow,
            definitions,
            showCols,
            addListFeatures = false,
            hideSearch = false,
            hidePagination = false,
            hideExport = false,
            hideOrder = false,
            showServicesSelect = false,
            error = false,
        } = this.props
        const pageNr = (filter && filter.page) || 0
        const result = pages[pageNr] || []
        const selection = filter.selection || []
        const allIsSelected =
            JSON.stringify([...selection].sort()) === JSON.stringify([...result].sort())

        const rows = result.map((id) => {
            const isSelected = selection.indexOf(id) !== -1
            const row = renderRow ? renderRow(id) : renderedRows[id]
            if (selectable) {
                const selector = (
                    <ListItemSelector
                        isSelected={isSelected}
                        select={this.selectItems.bind(this, id)}
                    />
                )
                return { ...row, selector, isSelected }
            }
            return row
        })

        let defWithOrder = definitions

        if (addListFeatures && !hideOrder) {
            const [orderCol, orderDir] = filter.sorting ? filter.sorting : ['', '']

            const sortClick = (newCol) => {
                const newDir = orderCol !== newCol || orderDir === 'DESC' ? 'ASC' : 'DESC'
                changeFilter({ sorting: [newCol, newDir] })
            }

            defWithOrder = {}
            Object.keys(definitions).forEach(
                (key) =>
                    (defWithOrder[key] = {
                        ...definitions[key],
                        name: definitions[key].noOrder ? (
                            definitions[key].name
                        ) : (
                            <div key={key} className={style.sort} onClick={() => sortClick(key)}>
                                {definitions[key].name}
                                {orderCol === key && (
                                    <Icon>
                                        {orderDir === 'ASC' ? 'arrow_drop_up' : 'arrow_drop_down'}
                                    </Icon>
                                )}
                            </div>
                        ),
                    })
            )
        }

        const onChangeSearch = (e) => {
            const query = e.target.value.trim()

            if (filter.query !== query) {
                changeFilter({ query, page: 0 })
            }
        }

        const { protocol, hostname } = window.location
        const exportFilter = {}

        Object.keys(filter).forEach((key) => {
            if (Array.isArray(filter[key])) {
                exportFilter[key + '[]'] = filter[key]
            } else {
                exportFilter[key] = filter[key]
            }
        })

        const noItems = result.length === 0 && !isFetching

        return (
            <div>
                {isFetching && <LinearLoader fixed />}
                {addListFeatures && (
                    <Row middle="xs">
                        {!hideSearch && (
                            <Col x>
                                <InputField
                                    controlled={false}
                                    classNames={style.search}
                                    compact
                                    placeholder="Search"
                                    onBlur={onChangeSearch}
                                    onKeyPress={(e) => e.key === 'Enter' && onChangeSearch(e)}
                                />
                            </Col>
                        )}
                        {showServicesSelect && (
                            <Col x>
                                <ServicesSelect
                                    wide
                                    value={filter.serviceIds || []}
                                    onChangeValue={(serviceIds) =>
                                        changeFilter({ serviceIds, page: 0 })
                                    }
                                />
                            </Col>
                        )}
                        {!hidePagination && (
                            <Col x>
                                <Pagination
                                    current={pageNr}
                                    pageSize={parseInt(filter.pageSize)}
                                    count={count}
                                    countOnPage={rows.length}
                                    onCountList={() =>
                                        changeFilter({ countList: true, didInvalidate: true })
                                    }
                                    onPageChange={(page) => changeFilter({ page })}
                                />
                            </Col>
                        )}
                        {!hideExport && (
                            <Col x style={{ marginLeft: 'auto' }}>
                                <Restricted location="export" checkWrite={false}>
                                    <PreIcon name="exit_to_app">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`${protocol}//${hostname}/api/${url.replace(
                                                /[^/]*$/,
                                                'export'
                                            )}?${queryString.stringify(exportFilter)}`}>
                                            Export
                                        </a>
                                    </PreIcon>
                                </Restricted>
                            </Col>
                        )}
                    </Row>
                )}
                <div className={`list ${compact ? 'compact' : ''} ${isFetching ? 'busy' : ''}`}>
                    <FlexTable
                        hideHeader={hideHeader || compact}
                        headerSelector={
                            selectable && (
                                <ListItemSelector
                                    isSelected={allIsSelected}
                                    select={this.selectItems.bind(
                                        this,
                                        allIsSelected ? [] : result
                                    )}
                                    disabled={noItems}
                                />
                            )
                        }
                        definitions={defWithOrder}
                        showCols={showCols}
                        rows={rows}
                    />
                    {error && <pre>Error! {JSON.stringify(error, null, 2)}</pre>}
                    {noItems && (
                        <Section x y>
                            <em className="c-grey">no items</em>
                        </Section>
                    )}
                </div>
            </div>
        )
    }
}

TableList.propTypes = {
    compact: PropTypes.bool,
    definitions: PropTypes.object,
    hideHeader: PropTypes.bool,
    renderItem: PropTypes.func,
    renderRow: PropTypes.func,
    selectable: PropTypes.bool,
    showCols: PropTypes.array,

    // props from container
    filter: PropTypes.object,
    changeFilter: PropTypes.func,
    pages: PropTypes.object,
    select: PropTypes.func,
    isFetching: PropTypes.any,
}

export default TableList
