import {Button, Icon} from "@advanza/ui";

const TestPage = () => {
        const Border = ({children}) => {
                return (
                    <div style={{padding: 10, border: '1px solid black', display : 'flex', gap: 10}}>
                            {children}
                    </div>
                )
        }
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10}}>
                <Border>
                    <Button name={'default'}> default </Button>
                    <Button name={'default'}> default (with Icon) <Icon name={'edit'}/> </Button>
                </Border>
                <Border>
                    <Button name={'btnReset'}> btnReset </Button>
                    <Button name={'btnReset'} disabled> btnReset (disabled) </Button>
                </Border>
                <Border>
                    <Button name={'cta'}> cta </Button>
                    <Button name={'cta'}> cta (with Icon) <Icon name={'edit'}/></Button>
                </Border>
                <Border>
                    <Button name={'yellow'}> yellow </Button>
                </Border>
                <Border>
                    <Button name={'yellowAlt'}> yellowAlt </Button>
                </Border>
                <Border>
                    <Button name={'bordered'}> bordered </Button>
                    <Button name={'bordered'}> bordered (with Icon) <Icon name={'edit'}/> </Button>
                </Border>
                <Border>
                    <Button name={'borderedPoppins'}> borderedPoppins </Button>
                    <Button name={'borderedPoppins'}> borderedPoppins (with Icon) <Icon name={'edit'}/></Button>
                </Border>
                <Border>
                    <Button name={'text'}> text </Button>
                    <Button name={'text'}> text (with icon) <Icon name={'edit'}/> </Button>
                    <Button name={'text'} disabled> text disabled </Button>
                    <Button name={'text'} disabled> text disabled (with icon) <Icon name={'edit'}/> </Button>
                </Border>
                <Border>
                    <Button name={'text2'}> text2 </Button>
                    <Button name={'text2'}> text2 (with icon) <Icon name={'edit'}/> </Button>
                </Border>
                <Border>
                    <Button name={'textReadMore'}> textReadMore </Button>
                    <Button name={'textReadMore'}> textReadMore (with icon) <Icon name={'edit'}/> </Button>
                </Border>
                <Border>
                    <Button name={'delete'}> delete </Button>
                </Border>
                <Border>
                    <Button name={'discard'}> discard </Button>
                </Border>
                <Border>
                    <Button name={'link'}> link </Button>
                </Border>
                <Border>
                    <Button name={'underline'}> underline </Button>
                </Border>
                <Border>
                    <Button name={'primary'}> primary </Button>
                </Border>
                <Border>
                    <Button name={'wide'}> wide </Button>
                </Border>
                <Border>
                    <Button name={'primaryText'}> primaryText </Button>
                </Border>
                <Border>
                    <Button name={'primaryText2'}> primaryText2 </Button>
                </Border>
                <Border>
                    <Button name={'newStyle'}> newStyle </Button>
                    <Button name={'newStyle'} disabled> newStyle (disabled)</Button>
                    <Button name={'newStyle'}> newStyle (with Icon) <Icon name={'edit'}/></Button>
                </Border>
                <Border>
                    <Button name={'square'}> square </Button>
                    <Button name={'square'}> square (with Icon) <Icon name={'edit'}/></Button>
                </Border>
                <Border>
                    <Button name={'blue'}> blue </Button>
                    <Button name={'blue'}> blue (with Icon) <Icon name={'edit'}/></Button>
                </Border>
        </div>
    )
}

export default TestPage
