import { useEffect, useState } from 'react'
import { call } from '@advanza/api'
import { format } from 'date-fns'
import { Button, Divider, Icon, LoadingDots } from '@advanza/ui'
import style from './LeadBoostersOffice.module.css'
import DatePicker from '../DatePicker'
import CheckButton from 'components/CheckButton'
import SafeButton from 'components/ui/SafeButton'
import { useAppDispatch } from 'hooks/hooks'
import { fetchProvider } from 'actions/providers'
import AutoRow from 'components/misc/AutoRow'

interface LeadBooster {
    lead_booster_id: number
    service_provider_id?: number
    date_start: string
    date_end?: string
}

interface NewLeadBooster {
    service_provider_id?: number
    date_start?: string
    date_end?: string
}

const useLeadBoosters = (providerId = 0) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [leadBoosters, setLeadBoosters] = useState<LeadBooster[]>([])
    const [newBooster, setNewBooster] = useState<NewLeadBooster | null>(null)
    const dispatch = useAppDispatch()

    const fetchLeadBoosters = async () => {
        setIsLoading(true)
        const result = await call(`office/lead-boosters/list/${providerId}`)
        setLeadBoosters(result.leadBoosters)
        setIsLoading(false)
    }
    const saveBooster = async () => {
        setIsSaving(true)
        try {
            await call(`office/lead-boosters/save/`, {
                json: { ...newBooster, service_provider_id: providerId },
            })
            setIsSaving(false)
            await fetchLeadBoosters()
            await dispatch(fetchProvider(providerId, true))
            setNewBooster(null)
        } catch (e) {
            alert(JSON.stringify(e, null, 2))
            setIsSaving(false)
        }
    }
    const changeBooster = (changes: NewLeadBooster) => {
        setNewBooster({ ...newBooster, ...changes })
    }
    const removeNewBooster = () => setNewBooster(null)
    const deleteBooster = async (id: number) => {
        setIsSaving(true)
        await call(`office/lead-boosters/delete/${id}`, { method: 'delete' })
        setIsSaving(false)
        await fetchLeadBoosters()
        await dispatch(fetchProvider(providerId, true))
    }

    useEffect(() => {
        fetchLeadBoosters()
    }, [])

    return {
        leadBoosters,
        saveBooster,
        deleteBooster,
        changeBooster,
        newBooster,
        removeNewBooster,
        isLoading,
        isSaving,
    }
}

const LeadBoostersOffice = ({ providerId }: Props) => {
    const {
        leadBoosters,
        saveBooster,
        isSaving,
        changeBooster,
        deleteBooster,
        isLoading,
        newBooster,
        removeNewBooster,
    } = useLeadBoosters(providerId)

    const hasNew = newBooster !== null
    return (
        <div className={isLoading || isSaving ? style.loading : ''}>
            {leadBoosters.map((booster) => {
                const today = new Date()
                const isActive =
                    new Date(booster.date_start) <= today &&
                    (!booster.date_end || new Date(booster.date_end) >= today)
                const isFuture = !isActive && new Date(booster.date_start) > today
                return (
                    <div key={booster.lead_booster_id} className={style.newBooster}>
                        <small>#{booster.lead_booster_id}</small>
                        <small>
                            <Icon
                                green={isActive}
                                name={isActive ? 'play_arrow' : isFuture ? 'update' : 'stop'}
                            />
                        </small>
                        <div>{booster.date_start}</div>
                        <span>→</span>
                        <div>{booster.date_end || '∞'}</div>
                        <SafeButton
                            buttonText={<Icon name="delete_forever" />}
                            action={() => deleteBooster(booster.lead_booster_id)}
                            successMessage="Deleted booster"
                        />
                    </div>
                )
            })}
            <Divider m />
            {hasNew && (
                <div>
                    <AutoRow noWrap>
                        <DatePicker
                            onChange={(e) => changeBooster({ date_start: e.target.value })}
                            value={newBooster.date_start}
                            name="Date start"
                        />
                        <DatePicker
                            onChange={(e) => changeBooster({ date_end: e.target.value })}
                            value={newBooster.date_end}
                            name="Date end"
                        />
                        <CheckButton
                            msg="Lifetime"
                            checked={!newBooster.date_end}
                            onChange={() =>
                                changeBooster({
                                    date_end: newBooster.date_end
                                        ? undefined
                                        : format(new Date(), 'yyyy-MM-dd'),
                                })
                            }
                        />
                    </AutoRow>
                    <Divider m />

                    <AutoRow>
                        <Button name="default" onClick={saveBooster}>
                            {isSaving ? <LoadingDots color="#fff" /> : 'Save booster'}
                        </Button>
                        <Button name="text" onClick={removeNewBooster}>
                            <Icon name="close" />
                        </Button>
                    </AutoRow>
                </div>
            )}
            {!hasNew && (
                <Button
                    name="text"
                    onClick={() => changeBooster({ date_start: format(new Date(), 'yyyy-MM-dd') })}>
                    Add booster
                </Button>
            )}
        </div>
    )
}

interface Props {
    providerId: number
}

export default LeadBoostersOffice
