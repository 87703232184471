import { InputField } from '@advanza/input'
import { Icon } from '@advanza/ui'
import { format as dateFormat, isValid } from 'date-fns'
import { useOutsideClick } from 'hooks/miscHooks'
import React, { ChangeEvent, useRef, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import style from './datePicker.module.css'
import rdpStyle from './datePickerRdp.module.css'

interface DatePickerProps {
    value?: string | null
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    name: string
    format?: string
    icon?: string
    placeholder?: string
    legend?: boolean
    maxWidth?: string | number
    error?: boolean
    large?: boolean
    alignRight?: boolean
    withIcon?: boolean
    dayPickerProps?: object
    opened?: boolean
}

const DatePicker: React.FC<DatePickerProps> = ({
    icon = 'date_range',
    name,
    placeholder,
    legend = false,
    value = null,
    maxWidth,
    error,
    large = true,
    alignRight = false,
    withIcon = true,
    opened = false,
    dayPickerProps,
    onChange,
    ...rest
}) => {
    const [showDayPicker, setShowDayPicker] = useState(false)
    const [query, setQuery] = useState<string | null>(null)
    const ref = useRef(null)
    const handleChange = (day: Date | null) => {
        let val = day && isValid(new Date(day)) ? dateFormat(day, 'yyyy-MM-dd') : null
        onChange({
            target: { name, value: val },
        } as ChangeEvent<HTMLInputElement>)
        setQuery(val)
    }

    useOutsideClick(ref, () => {
        setShowDayPicker(false)
    })
    const valid = value && value.length > 0 && isValid(new Date(value))
    const dateValue = valid && new Date(value)
    const formattedDay = dateValue ? dateFormat(dateValue, 'yyyy-MM-dd') : ''
    const pickerProps = {
        showOutsideDays: true,
        ...dayPickerProps,
    }
    const classString = [
        style.root,
        valid ? style.valid : '',
        error ? style.hasError : '',
        large ? style.large : ' ',
        alignRight ? style.alignRight : '',
    ].join(' ')

    return (
        <>
            <div className={classString} ref={ref}>
                {/*@ts-ignore*/}
                <InputField
                    before={withIcon && <Icon name={icon} />}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleChange(query ? new Date(query) : null)
                        }
                    }}
                    onFocus={() => setShowDayPicker(true)}
                    onChange={(e: any) => {
                        let val = e.target.value
                        setQuery(val)
                    }}
                    name={name}
                    placeholder={placeholder || name}
                    legend={legend}
                    error={error}
                    style={{ maxWidth }}
                    {...rest}
                    disabled={false}
                    value={query === null || value === null ? formattedDay : query}
                />
                {showDayPicker && (
                    <DayPicker
                        className={[
                            style.dayPicker,
                            opened ? style.opened : '',
                            dateValue && !legend ? style.withValue : '',
                        ].join(' ')}
                        classNames={rdpStyle}
                        selected={(date: Date) => {
                            if (!dateValue) {
                                return false
                            }
                            return date.toDateString() == dateValue.toDateString()
                        }}
                        onDayClick={handleChange}
                        {...pickerProps}
                    />
                )}
            </div>
        </>
    )
}

export default DatePicker
