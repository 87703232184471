import { mapsPromise } from '@advanza/advanza_generic/src/services/googleMapsLoader'
import { fetchAllMunicipalities } from 'actions/subscriptions'
import { getPoly } from 'components/subscriptions/ServiceAreaMapUltimate'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './MunicipalityMap.module.css'

const mapStyle = [
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'water',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
]

const countryCenters = {
    NL: 336,
    BE: 665,
    DE: 1194,
    ES: 1569, // madrid municipality_id
}
const defaultZoom = {
    NL: 7,
    BE: 7,
    DE: 6,
    ES: 6,
}

function useMunicipalities(countryCode) {
    const dispatch = useDispatch()
    const {
        entities: municipalities,
        result,
        mapIndexMap,
    } = useSelector((state) => state.subscriptions.municipalities)
    useEffect(() => {
        dispatch(fetchAllMunicipalities(countryCode))
    }, [countryCode])

    return { municipalities, result, mapIndexMap }
}

function useGoogleMaps(id) {
    const [google, setGoogle] = useState(null)
    useEffect(() => {
        mapsPromise(id).then((google) => {
            setTimeout(() => {
                setGoogle(google)
            })
        })
    }, [])

    return {
        google,
    }
}

let countryCodeInitial
const markers = []
const MunicipalitiesMap = ({
    height = 400,
    countryCode = 'nl',
    id = 'default',
    values,
    width,
    checkedValues = [],
    getHeatColorVars,
    getHoverContent,
    onClickMunicipality,
    markersData,
    showAudienceSize = true,
    onMapReady,
    ...rest
}) => {
    const [map, setMap] = useState(false)
    const [polys, setPolys] = useState(false)
    const [isFetchingPolys, setIsFetchingPolys] = useState(false)
    let oldPostalCode = null
    let oldMapCenter = null
    let oldUseHeatMap = null
    countryCodeInitial = countryCode
    const ref = useRef()
    const bannerRef = useRef()
    const [audienceSize, setAudienceSize] = useState(null)
    const { google } = useGoogleMaps(ref, id)
    useMunicipalities(countryCode)
    const { entities: municipalities, mapIndexMap } = useSelector(
        (state) => state.subscriptions.municipalities
    )
    useEffect(() => {
        if (google && google.maps && !map) {
            setMap(
                new google.maps.Map(ref.current, {
                    disableDefaultUI: true,
                    fullscreenControl: true,
                    mapTypeControlOptions: {
                        mapTypeIds: ['mapstyle', google.maps.MapTypeId.ROADMAP],
                    },
                    mapTypeId: 'mapStyle',
                })
            )
        }
        if (
            !map ||
            !google ||
            !municipalities ||
            !mapIndexMap ||
            !mapIndexMap[countryCode.toUpperCase()]
        ) {
            console.log({
                map,
                google,
                municipalities,
                mapIndexMap,
            })
            return
        }

        const mapCenter =
            rest.mapCenter || municipalities[countryCenters[countryCode.toUpperCase()]]
        const mapCenterChanged = mapCenter !== oldMapCenter
        const useHeatMapChanged = rest.useHeatMap !== oldUseHeatMap
        const countryChanged = rest.countryCode !== countryCodeInitial
        if (markersData) {
            markers.forEach((marker) => {
                marker.setMap(null)
            })
            for (const key in markersData) {
                const { lat, lon, title } = markersData[key]
                if (lat && lon) {
                    const image = 'https://static.trustoo.nl/misc/herring_marker_sm.png'
                    markers.push(
                        new google.maps.Marker({
                            position: {
                                lat: parseFloat(lat),
                                lng: parseFloat(lon),
                            },
                            map,
                            icon: image,
                            title,
                        })
                    )
                }
            }
        }
        if (
            useHeatMapChanged ||
            mapCenterChanged ||
            countryChanged ||
            (!oldMapCenter && !oldPostalCode) ||
            !polys
        ) {
            const centerPoint = mapCenter &&
                mapCenterChanged && {
                    lat: mapCenter.latitude,
                    lng: mapCenter.longitude,
                }
            const zoom =
                mapCenterChanged && mapCenter
                    ? mapCenter.mapZoom || defaultZoom[countryCode]
                    : defaultZoom[countryCode]

            if (polys) {
                // use panTo to smoothly go to the new center and prevent map refresh
                map.setOptions({ zoom, zoomControl: true })
                map.panTo(centerPoint)
            } else {
                map.setOptions({ center: centerPoint, zoom, zoomControl: true })
                map.mapTypes.set(
                    'mapStyle',
                    new google.maps.StyledMapType(mapStyle, { name: 'My Style' })
                )
            }
        }
        oldMapCenter = mapCenter
        oldUseHeatMap = rest.useHeatMap
        const emptyOpacity = 0
        let audience = 0
        const useHeatMap = rest.useHeatMap

        const getHeatColor = (munId) => {
            if (!useHeatMap || !getHeatColorVars) {
                return {}
            }
            return getHeatColorVars({ id: munId })
        }
        const handlePoly = (munId, municipality, isChecked, polys) => {
            const { fillColor, percentage } = getHeatColor(munId)
            const getContent = (isChecked) =>
                getHoverContent({ id: munId, municipality, percentage, isChecked })

            const onHover = () => {
                polys.forEach((poly) => {
                    const isChecked = poly ? poly.isChecked : isChecked
                    bannerRef.current.innerHTML = getContent(isChecked)
                    poly.setOptions && poly.setOptions({ fillOpacity: 0.6, strokeOpacity: 1 })
                })
            }
            const onMouseOut = () => {
                polys.forEach((poly) => {
                    bannerRef.current.innerHTML = ''
                    const isChecked = poly ? poly.isChecked : isChecked
                    poly.setOptions &&
                        poly.setOptions({
                            fillOpacity: isChecked
                                ? useHeatMap
                                    ? 0.8
                                    : 0.6
                                : useHeatMap
                                ? 0.6
                                : emptyOpacity,
                            strokeOpacity: 0.3,
                        })
                })
            }

            return {
                fillColor,
                onHover,
                getContent,
                onMouseOut,
            }
        }
        if (!polys) {
            setIsFetchingPolys(true)
            getPoly(countryCode).then((result) => {
                const polysObj = {}
                result.forEach(({ id, paths }, i) => {
                    const munId = id || mapIndexMap[countryCode.toUpperCase()][i]
                    const municipality = municipalities[munId]
                    const isChecked = checkedValues.indexOf(munId) !== -1
                    if (isChecked) {
                        audience += municipality.population
                    }

                    const { fillColor } = getHeatColor(munId, isChecked)
                    const poly = new google.maps.Polygon({
                        paths,
                        strokeColor: '#327bfe',
                        strokeOpacity: 0.3,
                        strokeWeight: 1,
                        fillColor: fillColor || 'hsl(219,99%,60%)',
                        fillOpacity: isChecked
                            ? useHeatMap
                                ? 0.8
                                : 0.6
                            : useHeatMap
                            ? 0.6
                            : emptyOpacity,
                        map: map,
                    })
                    const { onMouseOut, onHover, getContent } = handlePoly(
                        munId,
                        municipality,
                        isChecked,
                        [poly, ...(polysObj[munId] || [])]
                    )
                    if (polysObj[munId]) {
                        polysObj[munId].push({
                            poly,
                            munId,
                            isChecked,
                            name: municipality.name,
                        })
                    } else if (polysObj) {
                        polysObj[munId] = [
                            {
                                poly,
                                munId,
                                isChecked,
                                name: municipality.name,
                            },
                        ]
                    }

                    const onClick = () => {
                        const isChecked = polys ? polys[munId][0].isChecked : isChecked
                        bannerRef.current.innerHTML = getContent(isChecked)
                        onClickMunicipality &&
                            onClickMunicipality({
                                poly: polys && polys[munId],
                                id: munId,
                            })
                    }

                    poly.addListener('click', onClick)
                    poly.addListener('mouseover', onHover)
                    poly.addListener('mouseout', onMouseOut)
                })
                if (audience > 0) {
                    setAudienceSize(audience)
                }
                setPolys(polysObj)
                setIsFetchingPolys(false)
                onMapReady && onMapReady()
            })
        } else {
            Object.keys(polys).forEach((key) => {
                polys[key].forEach((polyItem, i) => {
                    const { poly, munId } = polyItem
                    const isCheckedNow = checkedValues.indexOf(munId) !== -1
                    if (isCheckedNow) {
                        audience += municipalities[munId].population
                    }
                    const { fillColor, onMouseOut, onHover } = handlePoly(
                        munId,
                        municipalities[munId],
                        isCheckedNow,
                        polys[munId]
                    )
                    polys[key][i].isChecked = isCheckedNow
                    poly.setOptions &&
                        poly.setOptions({
                            fillOpacity: isCheckedNow
                                ? useHeatMap
                                    ? 0.8
                                    : 0.6
                                : useHeatMap
                                ? 0.6
                                : emptyOpacity,
                            fillColor: fillColor || 'hsl(219,99%,60%)',
                        })

                    poly.addListener('mouseover', onHover)
                    poly.addListener('mouseout', onMouseOut)
                })
            })
        }
        if (audience > 0) {
            setAudienceSize(audience)
        }
    }, [
        google,
        map,
        values,
        countryCode,
        checkedValues,
        markersData,
        municipalities,
        rest.useHeatMap,
    ])
    useEffect(() => {
        return () => {
            setMap(null)
            setPolys(null)
            oldPostalCode = null
            oldMapCenter = null
        }
    }, [])

    return (
        <div className={style.map}>
            <div className={style.banner} ref={bannerRef}></div>
            <div
                id={id}
                key={id}
                style={{ width: '100%', height: height, minWidth: width }}
                ref={ref}
            />
            {showAudienceSize && (
                <div>
                    {audienceSize && audienceSize > 1000000
                        ? (audienceSize / 1000000).toFixed(2)
                        : (audienceSize / 1000).toFixed(2)}
                    {audienceSize > 1000000 ? 'm' : 'k'}
                </div>
            )}
        </div>
    )
}

export default MunicipalitiesMap
