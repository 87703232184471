import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { InputField } from '@advanza/input'
import { changeEntity } from '@advanza/redux_entity'
import { Icon } from '@advanza/ui'
import TableList from 'components/TableList'
import { useAppDispatch } from 'hooks/hooks'
import { useListPerCountry } from 'hooks/listHooks'
import keywordsSchema from 'schemes/keywordsSchema'

const useExpertiseKeywordsList = (filterId = 'all', defaultOptions = {}) =>
    useListPerCountry(
        filterId,
        {
            url: 'office/expertise-keywords/get-list',
            schema: keywordsSchema,
            reducerKey: 'expertiseKeywords',
        },
        {
            ...defaultOptions,
            sorting: ['keyword', 'ASC'],
        }
    )

const ExpertiseKeywordsList = ({ filterId = 'all' }) => {
    const dispatch = useAppDispatch()
    const {
        entities: { keywords = {} },
        extra: { definitionsExpertiseKeywords = {} } = {},
        ...list
    } = useExpertiseKeywordsList(filterId)

    const renderRow = (servicesKeywordId: number) => {
        const keyword = keywords[servicesKeywordId]

        const onSave = () => {
            dispatch(
                // @ts-ignore
                changeEntity({
                    store: 'expertiseKeywords',
                    name: 'keywords',
                    key: servicesKeywordId,
                    diff: { _isTouched: false },
                })
            )
            call('office/expertise-keywords/save', {
                json: [
                    {
                        services_keyword_id: servicesKeywordId,
                        keyword_url: keyword.keyword_url,
                    },
                ],
            })
        }

        return {
            cols: Object.keys(definitionsExpertiseKeywords).map((key) => {
                if (key === 'keyword_url') {
                    return (
                        <Row middle="xs" style={{ flexWrap: 'nowrap', width: 300 }}>
                            {/*@ts-ignore*/}
                            <Col x style={{ width: '100%' }}>
                                <InputField
                                    value={keyword.keyword_url}
                                    onChange={(e: any) => {
                                        dispatch(
                                            // @ts-ignore
                                            changeEntity({
                                                store: 'expertiseKeywords',
                                                name: 'keywords',
                                                key: servicesKeywordId,
                                                diff: {
                                                    keyword_url: e.target.value || null,
                                                    _isTouched: true,
                                                },
                                            })
                                        )
                                    }}
                                    onKeyPress={(e: any) =>
                                        e.key === 'Enter' && keyword._isTouched && onSave()
                                    }
                                />
                            </Col>
                            {keyword._isTouched && (
                                <Col x>
                                    <Icon
                                        name="save"
                                        style={{ color: '#5788ff', cursor: 'pointer' }}
                                        onClick={onSave}
                                    />
                                </Col>
                            )}
                        </Row>
                    )
                }

                return keyword[key]
            }),
        }
    }

    return (
        <TableList
            definitions={definitionsExpertiseKeywords}
            renderRow={renderRow}
            addListFeatures
            showServicesSelect
            {...list}
        />
    )
}

export default ExpertiseKeywordsList
