import { mapsPromise } from '@advanza/advanza_generic/src/services/googleMapsLoader'
import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { useSubscriptionArea } from 'hooks/providerHooks'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import style from './ServiceAreaListUtlimate.module.css'

const mapStyle = [
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'water',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
]

export async function getPoly(countryCode) {
    const hasPolys = countryCode === 'ES' || countryCode === 'DE'
    const poly =
        countryCode === 'BE'
            ? await import('./poly_admin4_BE.json')
            : hasPolys
            ? await call('geo/get-geo-shapes', { query: { countryCode } })
            : await import('./poly_admin2_nl.json')
    const result = []
    const makePath = (arr) => {
        return {
            lat: arr[1],
            lng: arr[0],
        }
    }
    if (hasPolys) {
        poly.items.forEach((municipality) => {
            const geoJson = JSON.parse(municipality.geo_shape)

            if (geoJson.type === 'Polygon') {
                let paths = []
                geoJson.coordinates.forEach((item) => {
                    paths = paths.concat(item.map(makePath))
                })
                result.push({
                    name: municipality.name,
                    id: municipality.municipality_id,
                    paths,
                })
            } else {
                geoJson.coordinates.forEach((item) => {
                    item.forEach((item) => {
                        let paths = []
                        paths = paths.concat(item.map(makePath))
                        result.push({
                            name: municipality.name,
                            id: municipality.municipality_id,
                            paths,
                        })
                    })
                })
            }
        })
    } else {
        poly.features.forEach((item, i) => {
            let paths = []
            item.geometry.coordinates.forEach((item) => {
                paths = paths.concat(
                    item[0].map((arr) => {
                        return {
                            lat: arr[1],
                            lng: arr[0],
                        }
                    })
                )
            })
            result.push({
                name: item.properties.name || 'Name',
                paths,
            })
        })
    }
    return result
}

export function useGoogleMaps() {
    const [google, setGoogle] = useState(null)
    useEffect(() => {
        mapsPromise().then((google) => {
            setTimeout(() => {
                setGoogle(google)
            })
        })
    }, [])

    return {
        google,
    }
}

let map = null
let polys = null
let oldPostalCode = null
let oldMapCenter = null
let oldUseHeatMap = null

const ServiceAreaMapUltimate = ({
    subscriptionId,
    height = 400,
    isLeadEstimator,
    estimation,
    ...rest
}) => {
    const ref = useRef()
    const bannerRef = useRef()
    const [audienceSize, setAudienceSize] = useState(null)
    const { google } = useGoogleMaps(ref)
    const {
        municipalities,
        subscriptionMunicipalities,
        toggleMunicipality,
        mapIndexMap,
        subscription,
        service,
        postalCode,
        saveMunicipalities,
        mapCenter,
    } = useSubscriptionArea(subscriptionId, isLeadEstimator)
    useEffect(() => {
        if (google && google.maps && !map) {
            map = new google.maps.Map(ref.current, {
                disableDefaultUI: true,
                mapTypeControlOptions: {
                    mapTypeIds: ['mapstyle', google.maps.MapTypeId.ROADMAP],
                },
                mapTypeId: 'mapStyle',
            })
        }
        if (
            !map ||
            !google ||
            !postalCode ||
            !subscription ||
            !service ||
            !municipalities ||
            !mapIndexMap ||
            !mapIndexMap[subscription.country_code]
        ) {
            return
        }
        const postalCodeChanged = oldPostalCode !== postalCode
        const mapCenterChanged = mapCenter !== oldMapCenter
        const useHeatMapChanged = rest.useHeatMap !== oldUseHeatMap
        if (
            postalCodeChanged ||
            useHeatMapChanged ||
            mapCenterChanged ||
            (!oldMapCenter && !oldPostalCode)
        ) {
            const centerPoint =
                mapCenter && mapCenterChanged
                    ? { lat: mapCenter.latitude, lng: mapCenter.longitude }
                    : { lat: postalCode.latitude, lng: postalCode.longitude }
            const distance = postalCode.distance
            const zoom =
                mapCenterChanged && mapCenter
                    ? mapCenter.mapZoom
                    : distance > 50
                    ? 6
                    : distance > 30
                    ? 7
                    : distance > 10
                    ? 9
                    : 10
            if (polys) {
                // use panTo to smoothly go to the new center and prevent map refresh
                map.setOptions({ zoom, zoomControl: true })
                map.panTo(centerPoint)
            } else {
                map.setOptions({ center: centerPoint, zoom, zoomControl: true })
                map.mapTypes.set(
                    'mapStyle',
                    new google.maps.StyledMapType(mapStyle, { name: 'My Style' })
                )
            }
        }
        oldPostalCode = postalCode
        oldMapCenter = mapCenter
        oldUseHeatMap = rest.useHeatMap
        const emptyOpacity = 0
        let audience = 0
        const useHeatMap = rest.useHeatMap && estimation && estimation.total
        const getHeatColor = (munId) => {
            if (!useHeatMap) {
                return {}
            }
            const leadEstimation =
                (useHeatMap &&
                    estimation &&
                    estimation.municipalities &&
                    estimation.municipalities[munId.toString()]) ||
                0
            const percentage = ((leadEstimation / estimation.total) * 100).toFixed(2)
            const heatPerc = leadEstimation && percentage

            const fillColor = useHeatMap && `hsl(${80 - heatPerc * 14},89%,46%)`
            return {
                heatPerc,
                percentage,
                fillColor,
            }
        }
        const handlePoly = (munId, municipality, isChecked, poly) => {
            const leadEstimation =
                (useHeatMap &&
                    estimation.municipalities &&
                    estimation.municipalities[munId.toString()]) ||
                0
            const { fillColor, percentage } = getHeatColor(munId)

            const getContent = (isChecked) =>
                ReactDOMServer.renderToStaticMarkup(
                    <Row center="xs" middle="xs">
                        <Col className={isChecked ? style.isChecked : ''} x>
                            {municipality.name}
                        </Col>
                        <Col x>
                            <i className="material-symbols-outlined">people</i>{' '}
                            {(municipality.population / 1000).toFixed(2)}k
                        </Col>
                        {service.highMunicipalityIds && (
                            <Col x>
                                <i className="material-symbols-outlined">sell</i>{' '}
                                {service.highMunicipalityIds.includes(munId)
                                    ? 'High rate'
                                    : 'Low rate'}
                            </Col>
                        )}
                        {useHeatMap && (
                            <Col x>
                                <b>{leadEstimation}</b> ({percentage}%)
                            </Col>
                        )}
                    </Row>
                )

            const onHover = (e) => {
                const isChecked = polys ? polys[munId][0].isChecked : isChecked
                bannerRef.current.innerHTML = getContent(isChecked)
                poly.setOptions({ fillOpacity: 0.6, strokeOpacity: 1 })
            }
            const onMouseOut = (e) => {
                bannerRef.current.innerHTML = ''
                const isChecked = polys ? polys[munId][0].isChecked : isChecked
                poly.setOptions({
                    fillOpacity: isChecked
                        ? useHeatMap
                            ? 0.8
                            : 0.6
                        : useHeatMap
                        ? 0.6
                        : emptyOpacity,
                    strokeOpacity: 0.3,
                })
            }

            return {
                fillColor,
                onHover,
                getContent,
                onMouseOut,
            }
        }
        if (!polys) {
            polys = {}
            getPoly(subscription.country_code).then((result) => {
                result.forEach(({ paths, id }, i) => {
                    const munId = id || mapIndexMap[subscription.country_code][i]
                    const municipality = municipalities[munId]
                    const isChecked = subscriptionMunicipalities.indexOf(munId) !== -1

                    if (isChecked) {
                        audience += municipality.population
                    }

                    const color = service.highMunicipalityIds.includes(munId)
                        ? '#0000ff'
                        : '#327bfe'
                    const poly = new google.maps.Polygon({
                        paths,
                        strokeColor: useHeatMap ? '#327bfe' : color,
                        strokeOpacity: 0.3,
                        strokeWeight: 1,
                        fillColor: useHeatMap ? getHeatColor(munId, isChecked).fillColor : color,
                        fillOpacity: isChecked
                            ? useHeatMap
                                ? 0.8
                                : 0.6
                            : useHeatMap
                            ? 0.6
                            : emptyOpacity,
                        map: map,
                    })
                    const { onMouseOut, onHover, getContent } = handlePoly(
                        munId,
                        municipality,
                        isChecked,
                        poly
                    )

                    if (polys[munId]) {
                        polys[munId].push({ poly, munId, isChecked, name: municipality.name })
                    } else {
                        polys[munId] = [{ poly, munId, isChecked, name: municipality.name }]
                    }

                    const onClick = (e) => {
                        toggleMunicipality(munId)
                        saveMunicipalities()
                        const isChecked = polys ? polys[munId][0].isChecked : isChecked
                        bannerRef.current.innerHTML = getContent(isChecked)
                    }

                    poly.addListener('click', onClick)
                    poly.addListener('mouseover', onHover)
                    poly.addListener('mouseout', onMouseOut)
                })
                if (audience > 0) {
                    setAudienceSize(audience)
                }
            })
        } else {
            Object.keys(polys).forEach((key) => {
                polys[key].forEach((polyItem, i) => {
                    const { poly, munId } = polyItem
                    const isCheckedNow = subscriptionMunicipalities.indexOf(munId) !== -1
                    if (isCheckedNow) {
                        audience += municipalities[munId].population
                    }
                    const { fillColor, onMouseOut, onHover } = handlePoly(
                        munId,
                        municipalities[munId],
                        isCheckedNow,
                        poly
                    )
                    polys[key][i].isChecked = isCheckedNow
                    poly.setOptions({
                        fillOpacity: isCheckedNow
                            ? useHeatMap
                                ? 0.8
                                : 0.6
                            : useHeatMap
                            ? 0.6
                            : emptyOpacity,
                        fillColor: fillColor || 'hsl(219,99%,60%)',
                    })

                    poly.addListener('mouseover', onHover)
                    poly.addListener('mouseout', onMouseOut)
                })
            })
        }
        if (audience > 0) {
            setAudienceSize(audience)
        }
    }, [
        google,
        postalCode,
        estimation,
        municipalities,
        subscriptionMunicipalities,
        rest.useHeatMap,
    ])
    useEffect(() => {
        return () => {
            map = null
            polys = null
            oldPostalCode = null
            oldMapCenter = null
        }
    }, [])

    return (
        <div className={style.map}>
            <div className={style.banner} ref={bannerRef}></div>
            <div style={{ width: '100%', height: height }} ref={ref} />
            <div>
                {audienceSize && audienceSize > 1000000
                    ? (audienceSize / 1000000).toFixed(2)
                    : (audienceSize / 1000).toFixed(2)}
                {audienceSize > 1000000 ? 'm' : 'k'}
            </div>
        </div>
    )
}

export default ServiceAreaMapUltimate
