import React from 'react'
import style from './AutoRow.module.css'

const AutoRow = ({
    children,
    alignTop = false,
    alignBottom = false,
    noWrap = false,
    gridGap = undefined,
    between= false,
    className = '',
    styles={}
}) => {
    const _className = [
        style.root,
        alignTop ? style.alignTop : '',
        alignBottom ? style.alignBottom : '',
        noWrap ? style.noWrap : '',
        between ? style.between : '',
        className,
    ].join(' ')
    return (
        <div style={{ gridGap,...styles }} className={_className}>
            {children}
        </div>
    )
}

export default AutoRow
