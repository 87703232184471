import React from 'react'
import Chart from 'react-google-charts'
import { useReclaimsList } from 'hooks/reclaimHooks'

const ReclaimStats = ({ filterId = 'all', defaultOptions = {} }) => {
    const { stats = [] } = useReclaimsList(filterId, defaultOptions)
    const data = stats
        ? stats.map((reason) => [
              reason.reason ? reason.reason : 'No reason',
              Number(reason.amount),
          ])
        : []
    data.unshift(['Reclaim', 'Reasons'])
    return (
        <Chart
            width={'500px'}
            height={'300px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
                title: 'Reclaims by reason',
            }}
        />
    )
}

export default ReclaimStats
