import { Divider } from '@advanza/ui'
import SearchPro from 'components/misc/SearchPro'
import ProfileTabs from 'components/ui/ProfileTabs'
import PropTypes from 'prop-types'
import React from 'react'
import AutoRow from 'components/misc/AutoRow'
import { speak } from 'components/misc/SkeuomorphicSwitch'

const NavigationTop = ({ title }) => {
    const userName = window._user.username
    const isTest = window.location.hostname !== 'office.trustoo.nl'
    const greet = () => {
        speak("Hello ",'en-GB',)
        speak(userName,'nl-NL',0.3)
    }
    return (
        <>
            <Divider s />
            <AutoRow styles={{justifyContent: 'space-between'}} noWrap>
                <AutoRow noWrap>
                    <div className='title-sm'>{title || 'Office'}</div>
                    <SearchPro />

                </AutoRow>
                <AutoRow styles={{ justifyContent: 'end' }} noWrap>
                    <ProfileTabs />
                    {isTest && <img src='https://static.trustoo.nl/app/dev/icons/apple-touch-icon.png' alt='Trustoo'
                                    onClick={greet}
                                    style={{ width: 33, height: 33,cursor:'pointer'}} />}
                    <small>{userName}</small>
                    <a href='/api/auth/logout'><button >logout</button></a>
                </AutoRow>
            </AutoRow>
            <Divider s />
        </>
    )
}

NavigationTop.propTypes = {
    title: PropTypes.string
}

export default NavigationTop
