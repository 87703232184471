import React, { useEffect, useRef } from 'react'
import style from './SkeuomorphicSwitch.module.css'

let timoutid = null
let speaking = null
const SkeuomorphicSwitch = ({
    label = 'Strobe Lights',
    onChange,
    value,
    name,
    withBg = false,
    withBorder = false,
    size = 120,
    white = false,
}) => {
    const ref = useRef({})
    useEffect(() => {
        loadVoices()
    }, [])
    const toggle = () => {
        const audio = new Audio(
            `https://static.trustoo.nl/misc/switch_click_${value ? 'off' : 'on'}.m4a`
        )
        // setIsOn(!isOn)
        onChange({ target: { value: value !== true, name } })
        audio.play()
        timoutid = setTimeout(() => {
            timoutid && clearTimeout(timoutid)
            speak(`${label} ${value ? 'off' : 'on'}`)
        }, 100)
    }
    const className = [
        style.container,
        withBg ? style.wBg : '',
        withBorder ? style.wBorder : '',
        white ? style.white : '',
    ].join(' ')
    return (
        <div className={className}>
            <div className={style.label}>{label}</div>
            <div
                className={[style.root, value === null ? '' : value ? style.on : style.off].join(
                    ' '
                )}
                style={{ fontSize: size }}
                ref={ref}
                onClick={toggle}></div>
            <div className={style.label}>Off</div>
        </div>
    )
}

export function loadVoices() {
    if (typeof window === 'undefined' || !('speechSynthesis' in window)) {
        return
    }
    speechSynthesis.getVoices()
}
export function speak(text = 'hello', lang='en-US',rate=1){

    if (typeof window === 'undefined' || !('speechSynthesis' in window)) {
        return
    }
    // cancel previous speaking
    if (speaking) {
        window.speechSynthesis.cancel(speaking)
    }
    var msg = new SpeechSynthesisUtterance()
    var voices = speechSynthesis.getVoices()
    // filter voices on language
    voices = voices.filter((voice) => voice.lang === lang)

    const voicePreferences = [
        'Microsoft Zira Desktop - English (United States)',
        'Trinoids',
        'Zarvox',
        'Jester',
    ]
    let selectedVoice = null
    // first try to find a voice that we like
    for (let i = 0; i < voicePreferences.length; i++) {
        const voice = voices.find((voice) => voice.name === voicePreferences[i])
        if (voice) {
            selectedVoice = voice
            break
        }
    }
    // pick a random voice
    if (!selectedVoice) {
        selectedVoice = voices[Math.floor(Math.random() * voices.length)]
    }

    msg.voice =selectedVoice
    console.log('speaking with voice', msg.voice?.name)
    msg.text = text
    msg.lang = lang
    msg.rate = rate
    speaking = window.speechSynthesis.speak(msg)
}


export default SkeuomorphicSwitch
