import { AutoGrowField, Select } from '@advanza/advanza_generic'
import { toCamelBacked } from '@advanza/func'
import { InputField } from '@advanza/input'
import SimpleColorPicker from 'components/input/SimpleColorPicker'
import React from 'react'
import Loadable from 'react-loadable'
import CheckButton from '../CheckButton'
import DatePicker from '../DatePicker'
import GeoAutoComplete from '../GeoAutoComplete'
import LinearLoader from '../LinearLoader'
import SkeuomorphicSwitch from 'components/misc/SkeuomorphicSwitch'

export const Loader = (opts) =>
    Loadable({
        loading: <div>sd</div>,
        ...opts,
    })

const MobilePhoneInputGlobal = Loader({
    loading: () => <LinearLoader fixed />,
    loader: () => import('../users/GlobalMobilePhoneInput'),
})

class EntityComponent extends React.Component {
    constructor(props) {
        super(props)
        this.getEntity = this.getEntity.bind(this)
        this.getEntities = this.getEntities.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onChangeEntity = this.onChangeEntity.bind(this)
        this.getSaveFunc = this.getSaveFunc.bind(this)
        this.renderInput = this.renderInput.bind(this)
        this.onGeoPlaceChange = this.onGeoPlaceChange.bind(this)
        this.validate = this.validate.bind(this)
        this.getFields = this.getFields.bind(this)
        this.getDeleteFunc = this.getDeleteFunc.bind(this)
        this.delete = this.delete.bind(this)
        this.save = this.save.bind(this)
        this.isVisible = this.isVisible.bind(this)
        this.onChangeAndSave = this.onChangeAndSave.bind(this)
        this.hasAccess = this.hasAccess.bind(this)
        this.onChangeEntityAndSave = this.onChangeEntityAndSave.bind(this)
        this.onChangeOtherEntity = this.onChangeOtherEntity.bind(this)
        this.addToRelation = this.addToRelation.bind(this)
        this.options = {}
    }

    hasAccess(mode = 'r') {
        const { permissionLocation } = this.props
        const permissions = window._user.permissions
        if (!permissionLocation) {
            return true
        }
        return permissions[permissionLocation] && permissions[permissionLocation][mode]
    }

    getEntity() {
        return this.props.entity || {}
    }

    getEntities() {
        return this.props.entities
    }

    getSaveFunc() {
        return this.props.save
    }

    getDeleteFunc() {
        return this.props.delete
    }

    getFields() {
        const fields = typeof this.editFields === 'function' ? this.editFields() : this.editFields
        if (!this.hasAccess('w')) {
            const fieldsWithReadOnly = {}
            Object.keys(fields).forEach((key) => {
                fieldsWithReadOnly[key] = { ...fields[key], readOnly: true, disabled: true }
            })
            return fieldsWithReadOnly
        }

        return fields
    }

    validate() {
        const errors = this.getEntity()._errors || {}
        let isValid = true
        const newErrors = {}
        Object.keys(this.getFields()).forEach((field) => {
            const { validator, error } = this.getFields()[field]

            const value = this.getEntity()[field]
            const valid = validator ? validator(value, this.getEntity()) : true

            if (!valid) {
                isValid = false
                newErrors[field] = error || true
            }
        })

        if (!isValid) {
            this.onChangeEntity({ _errors: { ...errors, ...newErrors } })
        }

        return isValid
    }

    save(params) {
        if (!this.validate() || this.getEntity()._saving || !this.hasAccess('w')) {
            return Promise.reject()
        }
        const entity = this.getEntity()
        const entities = this.getEntities()
        const save = this.getSaveFunc()

        this.onChangeEntity({ _saving: true }, { dontTouch: true })

        // also update _saving, _beforeSave for relations
        if (this.options.relations) {
            this.options.relations.forEach(({ relationField, name: _name }) => {
                const name = _name || toCamelBacked(relationField)
                const relationValue = entity[relationField]
                const relationArr = Array.isArray(relationValue)
                    ? relationValue
                    : relationValue
                    ? [relationValue]
                    : []

                relationArr.forEach((key) =>
                    this.onChangeOtherEntity({
                        store: this.options.store,
                        name,
                        key,
                        diff: {
                            _saving: true,
                            _beforeSave: (entities[name] || {})[key] || {},
                        },
                    })
                )
            })
        }

        return save(params).then(
            (response) => {
                this.onChangeEntity({ _beforeSave: entity, _saving: false }, { dontTouch: true })

                // for relations set the primary key and other changes from the server (only send these changed fields back)
                // update the _beforeSave because these changes don't need saving, update _saving
                if (this.options.relations) {
                    this.options.relations.forEach(({ relationField, name: _name }) => {
                        const name = _name || toCamelBacked(relationField)
                        const relationValue = entity[relationField]
                        const relationArr = Array.isArray(relationValue)
                            ? relationValue
                            : relationValue
                            ? [relationValue]
                            : []
                        const changes =
                            (response &&
                                response.relationChanges &&
                                response.relationChanges[relationField]) ||
                            {}

                        relationArr.forEach((key) => {
                            const change = changes[key] || {}
                            const entities = this.getEntities()

                            this.onChangeOtherEntity({
                                store: this.options.store,
                                name,
                                key,
                                dontTouch: true,
                                diff: {
                                    ...change,
                                    _beforeSave: {
                                        ...(((entities[name] || {})[key] || {})._beforeSave || {}),
                                        ...change,
                                    },
                                    _saving: false,
                                },
                            })
                        })
                    })
                }

                this.afterSaveSuccess && this.afterSaveSuccess()
                return Promise.resolve(response)
            },
            (response) => {
                const { error, ...fields } = response
                if (error === 'fields') {
                    const errors = entity._errors || {}
                    Object.keys(fields).forEach((field) => {
                        errors[field] = JSON.stringify(response[field]) || true
                    })
                    this.onChangeEntity({ _errors: errors, _saving: false }, { dontTouch: true })
                } else {
                    this.onChangeEntity({ _error: error, _saving: false }, { dontTouch: true })
                }
                return Promise.reject(response)
            }
        )
    }

    delete(params) {
        const deleteFunc = this.getDeleteFunc()

        this.onChangeEntity({ _saving: true })

        return deleteFunc(params).then(
            () => {
                this.onChangeEntity({ _saving: false })
            },
            (response) => {
                this.onChangeEntity({ _saving: false, _error: response.error })
                return Promise.reject(response)
            }
        )
    }

    isVisible(name) {
        const fieldOptions = this.getFields()[name]
        return !fieldOptions.isHidden
    }

    onGeoPlaceChange(place, map) {
        const components = place.address_components || []
        components.map((component) => {
            const mapToState = component.types && map[component.types[0]]
            if (mapToState) {
                this.onChangeEntity({ [map[component.types[0]]]: component.long_name })
            }
        })
        Object.keys(map).forEach((key) => {
            if (place[key]) {
                this.onChangeEntity({ [map[key]]: place[key] })
            }
        })

        const location = place.geometry && place.geometry.location
        if (location && map.lat && map.lng) {
            this.onChangeEntity({
                [map.lat]: location.lat(),
                [map.lng]: location.lng(),
            })
        }
    }

    renderInput(name, saveAfterChange) {
        const fieldOptions = this.getFields()[name]
        if (fieldOptions.isHidden) {
            return null
        }
        const entity = this.getEntity()
        const errors = entity._errors || {}
        const changeFunc = saveAfterChange ? this.onChangeAndSave : this.onChange
        const entityKey = fieldOptions.name || name
        const isDirty = entity._beforeSave && entity[entityKey] !== entity._beforeSave[entityKey]

        if(fieldOptions.type === 'skeuomorphicBoolean') {
            return      <SkeuomorphicSwitch
                onChange={changeFunc}
                size={100}
                value={entity[entityKey]}
                label={fieldOptions.msg}
                name={entityKey}
                {...fieldOptions}
            />
        }
        if (fieldOptions.type === 'boolean') {
            const isChecked = entity[entityKey] === true || entity[entityKey] === '1'
            return (
                <CheckButton
                    onChange={changeFunc}
                    value={!isChecked}
                    checked={isChecked}
                    error={errors[entityKey]}
                    name={entityKey}
                    {...fieldOptions}
                />
            )
        }

        if (fieldOptions.type === 'select') {
            return (
                <Select
                    value={entity[entityKey]}
                    onChange={fieldOptions.multiple ? null : changeFunc}
                    error={errors[entityKey]}
                    {...fieldOptions}
                    name={entityKey}
                />
            )
        }

        if (fieldOptions.type === 'geo') {
            return (
                <GeoAutoComplete
                    name={entityKey}
                    icon={false}
                    state={{
                        value: entity[entityKey],
                        error: errors[entityKey],
                    }}
                    options={{
                        types: fieldOptions.types || ['establishment'],
                        componentRestrictions: { country: fieldOptions.country || 'NL' },
                    }}
                    onPlaceChange={(place) => this.onGeoPlaceChange(place, fieldOptions.map || {})}
                    {...fieldOptions}
                    onChange={changeFunc}
                />
            )
        }
        if (fieldOptions.type === 'mobilePhone') {
            return (
                <MobilePhoneInputGlobal
                    state={{
                        value: entity[entityKey] || '',
                        error: errors[entityKey],
                    }}
                    name={entityKey}
                    onChange={changeFunc}
                    {...fieldOptions}
                />
            )
        }
        if (fieldOptions.type === 'date') {
            return (
                <DatePicker
                    value={entity[entityKey] || ''}
                    error={errors[entityKey]}
                    onChange={changeFunc}
                    {...fieldOptions}
                    name={entityKey}
                />
            )
        }
        if (fieldOptions.type === 'color') {
            return (
                <SimpleColorPicker
                    value={entity[entityKey] || ''}
                    error={errors[entityKey]}
                    onChange={changeFunc}
                    {...fieldOptions}
                    name={entityKey}
                />
            )
        }
        if (fieldOptions.type === 'textArea') {
            return (
                <div>
                    <AutoGrowField
                        className="message-grow-field"
                        maxRows={window.isMobile ? 4 : 12}
                        onChange={changeFunc}
                        cursorToEnd
                        name={entityKey}
                        value={entity[entityKey]}
                        {...fieldOptions}
                    />
                    {errors[entityKey] && <div className="error">Error: {errors[entityKey]}</div>}
                </div>
            )
        }

        return (
            <InputField
                placeholder={fieldOptions.msg}
                name={entityKey}
                onChange={changeFunc}
                type={fieldOptions.type}
                value={
                    fieldOptions.valueFormatter && !isDirty
                        ? fieldOptions.valueFormatter(entity[entityKey])
                        : entity[entityKey]
                }
                error={errors[entityKey]}
                {...fieldOptions}
            />
        )
    }

    onChangeEntity(entityDiff, options) {
        const { changeEntity } = this.props
        changeEntity(entityDiff, options)
    }

    onChangeEntityAndSave(options) {
        this.onChangeEntity(options)
        this.save()
    }

    onChange(e) {
        const { name, value, type } = e.target
        const errors = { ...(this.getEntity()._errors || {}), [name]: false }
        const typedValue =
            type === 'number'
                ? value.indexOf('.') > 0
                    ? parseFloat(value)
                    : parseInt(value)
                : value
        return this.onChangeEntity({ [name]: typedValue, _errors: errors })
    }

    onChangeAndSave(e) {
        this.onChange(e)
        this.save()
    }

    onChangeOtherEntity(options) {
        const { changeOtherEntity } = this.props
        changeOtherEntity(options)
    }

    addToRelation(relationField, diff) {
        const name =
            (this.options.relations &&
                (
                    this.options.relations.find(
                        (relation) => relation.relationField === relationField
                    ) || {}
                ).name) ||
            toCamelBacked(relationField)
        const newId = Math.random().toString(36).substr(2, 5)

        this.onChangeOtherEntity({
            store: this.options.store,
            name,
            key: newId,
            diff: {
                ...diff,
                newId,
            },
        })

        const relationValue = this.getEntity()[relationField]
        this.onChangeEntity({
            [relationField]: Array.isArray(relationValue) ? relationValue.concat(newId) : newId,
        })
    }
}

EntityComponent.propTypes = {}

export default EntityComponent
