import { Radio } from '@advanza/input'
import Cookies from 'js-cookie'
import { getFlag } from 'misc/flags'
import React from 'react'
import style from './countryToggle.module.css'
import { useDispatch, useSelector } from 'react-redux'

const CountryToggle = ({ setInCookie = true, inline }) => {
    const dispatch = useDispatch()
    const value = useSelector((state) => state.countries).countries

    return (
        <div className={style.root}>
            <Radio
                value={value}
                inline={inline}
                classNames={{
                    checked: style.checked,
                    label: style.label,
                }}
                options={[
                    { name: getFlag('nl'), value: 'NL', title: 'Nederland' },
                    { name: getFlag('be'), value: 'BE', title: 'België' },
                    { name: getFlag('de'), value: 'DE', title: 'Deutschland' },
                    { name: getFlag('es'), value: 'ES', title: 'España' },
                    // { name: getFlag('az'), value: 'az', title: 'Azerbadjan' },
                ]}
                onChange={(e) => {
                    const { value } = e.target
                    if (setInCookie) {
                        Cookies.set('_trustoo_office_country', value)
                    }
                    dispatch({ type: 'CHANGE_COUNTRY', countries: [value] })
                    dispatch({ type: 'INVALIDATE_PROVIDERS' })
                    dispatch({ type: 'INVALIDATE_SERVICES' })
                    dispatch({ type: 'INVALIDATE_REQUESTS' })
                    dispatch({ type: 'INVALIDATE_INVOICES' })
                }}
            />
        </div>
    )
}

export default CountryToggle
