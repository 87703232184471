import Modal, { useScrollModal } from '@advanza/modal'
import { Button, Divider, HoverText } from '@advanza/ui'
import { PauseTypeSet } from 'components/pauses/pauseEnums'
import { addDays, format } from 'date-fns'
import { useProvidersDate } from 'hooks/providersDatesHooks'
import { Provider } from 'interfaces/Providers'

interface Props {
    provider: Provider
    onSucces?: () => void
    store?: string
    disabled?: boolean
}

const SnoozeDateAdd = ({ provider, onSucces, store, disabled }: Props) => {
    const { openModal, renderModal } = useScrollModal()
    const { onChangeEntity, onSave, isSaving, errors, setErrors, onCancel } = useProvidersDate(
        'new',
        onSucces,
        store
    )

    const onOpen = () => {
        onChangeEntity(
            {
                service_provider_id: provider.service_provider_id,
                pause_type: PauseTypeSet.SNOOZE,
                date_from: format(new Date(), 'yyyy-MM-dd'),
                date_to: format(addDays(new Date(), 6 * 7 - 1), 'yyyy-MM-dd'),
            },
            true
        )
        openModal()
    }

    const editModal = renderModal({
        heading: 'Are you sure you want to snooze this provider?',
        headingBgColor: 'transparent',
        body: (
            <>
                <Divider m />
                This will drastically limit the amount of requests they will receive for the
                upcoming 6 weeks. The profile will no longer be visible in the Top 10 and will not
                be represented in the Match2More. If the provider becomes Premium or when the 6
                weeks have passed, the snooze will automatically be removed.
            </>
        ),
        buttons: [
            {
                text: 'Close',
                name: 'link',
                func: onCancel,
                disabled: isSaving,
            },
            {
                text: isSaving ? 'Saving...' : 'Yes, snooze for 6 weeks',
                func: onSave,
                disabled: isSaving,
            },
        ],
        closeButton: {
            func: onCancel,
        },
        maxWidth: '420px',
    })

    const button = (
        <Button name="text" disabled={disabled} onClick={onOpen}>
            Add snooze
        </Button>
    )

    return (
        <>
            {disabled ? (
                /* @ts-ignore */
                <HoverText
                    text="You can't snooze this provider because it has already been snoozed in the last 6 months."
                    trigger={button}
                />
            ) : (
                button
            )}
            {editModal}
            {/* @ts-ignore */}
            <Modal open={errors} close={() => setErrors(null)}>
                <h3>Error:</h3>
                <Divider />
                {JSON.stringify(errors)}
            </Modal>
        </>
    )
}

export default SnoozeDateAdd
