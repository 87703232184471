import { Divider } from '@advanza/ui'
import ExpertiseKeywordsList from 'components/keywords/ExpertiseKeywordsList'
import Page from 'components/ui/Page'

const ExpertiseKeywordsPage = () => (
    // @ts-ignore
    <Page title="Expertise keywords">
        <Divider m />
        <h1>Expertise keywords</h1>
        <Divider m />
        <ExpertiseKeywordsList />
    </Page>
)

export default ExpertiseKeywordsPage
