import { Avatar } from '@advanza/advanza_generic'
import { Col, Row } from '@advanza/grid'
import { Icon } from '@advanza/ui'
import { getActiveStatus } from 'components/providers/_providerActiveStatus'
import ProspectLabel from 'components/providers/ProspectLabel'
import ProviderLabelPicker from 'components/providers/ProviderLabelPicker'
import A from 'components/ui/A'
import FormattedTime from 'components/ui/FormattedTime'
import HappinessIcon from 'components/ui/HappinessIcon'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import ProvidersListContainer from '../../containers/ProvidersListContainer'
import TableList from '../TableList'
import LimitReachedLabel from './LimitReachedLabel'

class ProvidersList extends React.Component {
    constructor(props) {
        super(props)
        this.renderColumns = this.renderColumns.bind(this)
        this.renderRow = this.renderRow.bind(this)
    }

    profileStatus(provider) {
        const map = {
            0: {
                icon: 'power_off',
                color: 'rgb(238, 56, 109)',
            },
            1: {
                icon: 'power',
                color: 'rgb(12, 214, 5)',
            },
            2: {
                icon: 'pending',
                color: 'rgb(244, 101, 17)',
            },
            3: {
                icon: 'pause_circle',
                color: 'rgb(238, 56, 109)',
            },
            4: {
                icon: 'delete_forever',
                color: 'purple',
            },
            5: {
                icon: 'thumb_down_alt',
                color: 'rgb(238, 56, 109)',
            },
            6: {
                icon: '👻',
                color: 'rgb(238, 56, 109)',
            },
            7: {
                icon: 'fiber_new',
                color: 'rgb(244, 101, 17)',
            },
            fallback: {
                icon: 'beach_access',
                color: 'rgb(238,117,230)',
            },
            franchise: {
                icon: 'layers',
                color: 'rgb(100,100,100)',
            },
        }

        if (provider.franchise_status) {
            return map.franchise
        }

        return map[provider.status] || map.fallback
    }

    getUrl(id, slug) {
        if (slug) {
            return `/service/providers/provider/${id}/${slug}`
        }
        return `/service/providers/provider/${id}`
    }

    renderColumns(id) {
        const { providerEntities, services, includeLimitReached } = this.props
        const { providers, users, leadSettings } = providerEntities
        const provider = providers[id]
        const leadSetting = leadSettings[provider.lead_setting]
        const user = users[provider.user]
        const proScore = Math.round(provider.total_score * 100)
        const scoreStyle = {
            color: `hsl(${proScore}, 100%, 40%, 1)`,
        }
        const activeStatus = getActiveStatus(provider)

        const profileStatus = this.profileStatus(provider)
        const cols = [
            <small className="c-grey">
                <Link to={this.getUrl(id)}>#{id}</Link>
            </small>,
            <span>
                {provider.slow_response ? (
                    <i style={{ fontSize: 21 }}>🐢</i>
                ) : (
                    <Icon
                        fontSize={20}
                        name={activeStatus.icon}
                        style={{ color: activeStatus.color }}
                    />
                )}
            </span>,
            <Icon fontSize={20} name={profileStatus.icon} style={{ color: profileStatus.color }} />,
            <A className="classic" to={this.getUrl(id)}>
                <Row middle="xs">
                    <Col x>
                        <Avatar sizePx={25} useLetterColor user={user} />
                    </Col>
                    <Col xs>
                        {provider.business_name}{' '}
                        {provider.claimed && <Icon name="verified_user" fontSize={16} primColor />}
                        {provider.isFreeTrial && <Icon name="explosion" fontSize={16} green />}
                        <small className="text">{provider.sublocality}</small>
                    </Col>
                </Row>
            </A>,
            services && services[provider.serviceId]
                ? services[provider.serviceId].name
                : provider.service_id,
            <small className="c-grey">{provider.cityName}</small>,
            <div style={{ maxWidth: 200 }}>
                <ProviderLabelPicker entityId={id} hideTypes={['prospect']} />
            </div>,
            <small>
                <ProspectLabel providerId={id} />
            </small>,
            <span style={scoreStyle}>{proScore}</span>,
            <small>{provider.nr_ratings}</small>,
            <Row nm>
                <LimitReachedLabel leadSetting={leadSetting} />
            </Row>,
            <small className="text">
                <FormattedTime date={provider.created} />
            </small>,
            provider.isSigned && <HappinessIcon happiness={provider.happinessTotal} />,
            <Link to={this.getUrl(id, 'company')}>
                <Icon name="settings" />
            </Link>,
        ]
        if (includeLimitReached) {
            cols.push(provider.weekLimit, <span>{provider.limitsReached}x</span>)
        }
        return cols
    }

    renderRow(id) {
        const { backgroundColor } = getActiveStatus(this.props.providerEntities.providers[id])
        return {
            url: this.getUrl(id),
            cols: this.renderColumns(id),
            backgroundColor,
            id,
        }
    }

    definitions = {
        id: { name: '#', preventRedirect: true },
        ActiveStatus: { name: '>' },
        status: { name: '*' },
        name: { name: 'name' },
        service: { name: 'service' },
        city: { name: 'city' },
        label: { name: 'label', preventRedirect: true },
        prospect: { name: '€' },
        score: { name: 'score' },
        reviews: { name: <Icon fontSize={16} name="stars" /> },
        extra: { name: '' },
        time: { name: 'time' },
        happiness: { name: <Icon fontSize={16} name="sentiment_satisfied" /> },
        linkToEdit: { name: <Icon name="settings" fontSize={16} />, preventRedirect: true },
    }

    render() {
        const { selectable, includeLimitReached, ...rest } = this.props
        const defs = includeLimitReached
            ? {
                  ...this.definitions,
                  ...{
                      weekLimit: { name: 'Week limit' },
                      limitsReached: { name: 'Nr times reached' },
                  },
              }
            : this.definitions
        return (
            <TableList
                {...rest}
                selectable={selectable}
                renderRow={this.renderRow}
                definitions={defs}
            />
        )
    }
}

ProvidersList.propTypes = {
    filter: PropTypes.object,
    isFetching: PropTypes.bool,
    pages: PropTypes.any,
    providerEntities: PropTypes.object,
    services: PropTypes.object,
}

export default ProvidersListContainer(ProvidersList)
